import React, { useEffect } from 'react';
import Glide, { Controls, Swipe } from '@glidejs/glide/dist/glide.modular.esm';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { PageTitle } from '../index';

const MobileSpeakers = styled.div`
  @media (min-width: 960px) {
    display: none;
  }
`;

const Slide = styled.li`
  position: relative;
  list-style: none;
  padding-bottom: 20px;
  margin-bottom: 0;
  @media (min-width: 960px) {
    padding-bottom: 0;
    cursor: grab;
  }
`;

const SlideMobileImgContainer = styled.div`
  width: auto;
  //height: 30vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
`;

const SliderMobileImg = styled(Img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const SlideMobileDesc = styled.div`
  position:relative;
  z-index: 2;
  padding: 0 35px;
`;

const SlideMobileDescTitle = styled.div`
  position: relative;
  font-family: 'Morganite Black', serif;
  font-size: 35px;
  text-transform: uppercase;
  top: -30px;
  left: 10px;
`;

const SlideMobileDescText = styled.div`
  font-family: "Montserrat Medium", serif;
  font-size: 12px;
  line-height: 1.5;
  position: relative;
  top: -10px;
`;

const SpeakerMobile = ({ name, desc, img }) => (
  <Slide className="glide__slide">
    <SlideMobileImgContainer>
      <SliderMobileImg fluid={img} alt={name} />
    </SlideMobileImgContainer>
    <SlideMobileDesc>
      <SlideMobileDescTitle className="title">
        {name}
      </SlideMobileDescTitle>
      <SlideMobileDescText className="text">
        {desc}
      </SlideMobileDescText>
    </SlideMobileDesc>
  </Slide>
);

let speakersSlider;

const SpeakersMobile = ({ data }) => {
  useEffect(() => {
    speakersSlider = new Glide('.glide__mowcy', {
      type: 'slider',
      perView: 2,
      focusAt: 'center',
      startAt: 0,
    });

    speakersSlider.mount({ Swipe, Controls });
  }, []);

  return (
    <MobileSpeakers>
      <SpeakerMobile
        name="Leo Bigger"
        img={data.leobigger_mobile.childImageSharp.fluid}
        desc={'Pastor wiodący ICF Zürich i&nbsp;ICF Movement - Międzynarodowej Wspólnoty Chrześcijańskiej, która tworzy Kościoły Ewangelikalne na terenie całej Europy. Misją Leo jest odkrywanie nowych sposobów na zbliżanie ludzi do Boga poprzez transmisję kazań w social mediach, a także wydawanie magazynu pokazującego życie kościoła zza kulis. Razem z żoną Susanną prowadzą program “a new church experience” emitowany w telewizji.'}
      />
      <SpeakerMobile
        name="René Wagner"
        img={data.rene_mobile.childImageSharp.fluid}
        desc="Założyciel i główny pastor ICF w Leipzig, Dreźnie i Halle. Z wielką pasją dzieli się Ewangelią w jednym z najbardziej ateistycznych regionów na świecie. Jego inspirujące kazania motywują do życia w zgodzie z biblijnym słowem."
      />
      <SpeakerMobile
        name="Wiktoria Maciejewicz"
        img={data.wiktoria_mobile.childImageSharp.fluid}
        desc={'Jeden z liderów Warszawskiego Składu Soli - spotkań dla młodych ludzi w Zborze Stołecznym KZ Sienna w Warszawie. Razem z mężem odpowiada za organizację Bazy Centrum. Kocha młodych ludzi i w każdym z nich dostrzega talent oraz unikalną wartość. Swoim poświęceniem i miłością do Boga udowadnia, że mając wiarę, wszystko jest możliwe.'}
      />
      <SpeakerMobile
        name="Karol Michalak"
        img={data.michalak_mobile.childImageSharp.fluid}
        desc="Pastor kościoła Centrum Chrześcijańskie Zwycięstwo w Łodzi. Warsztatowiec, artysta, absolwent łódzkiej ASP. Nie wstydzi się mówić głośno o tematach trudnych. Szczególnie podkreśla rolę Ducha Świętego w życiu każdego wierzącego."
      />
      <SpeakerMobile
        name="Arek Kuczyński"
        img={data.arkadiusz_mobile.childImageSharp.fluid}
        desc={'Pastor Stołecznego Zboru Kościoła Zielonoświątkowego w Warszawie. Zachęca do zmian w myśleniu o Kościele jako instytucji i otwarciu się na kulturę współdziałania. W swoich kazaniach stara się kompleksowo poruszyć każdy temat, biorąc pod uwagę różne punkty widzenia.'}
      />
    </MobileSpeakers>
  );
};

export default SpeakersMobile;
