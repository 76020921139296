import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Glide, { Swipe, Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm';

import { PageTitle } from './index';

const Page = styled.div`
  position: relative;
  color: #F5F3F0;
  padding-bottom: 50px;
  //display: none;
  //@media (min-width: 768px) {
  //  display: block;
  //}
`;

const PageBackground = styled.div`
  position: absolute;
  background-color: #F79065;
  width: 100%;
  height: 100%;
  z-index: -1;
  @media (min-width: 768px) {
    width: calc(100% - 40px);
    margin: 0 20px;
  }
`;

const SessionsContainer = styled.div`
  display: grid;
  grid: 1fr 1fr 1fr / 1fr;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
  grid-gap: 20px;
  
  @media (min-width: 1100px) {
    grid: 1fr 1fr / 1fr 1fr;
    padding: 0 40px;
  }
`;

const Session = styled.div`
  position: relative;
  display: flex;
`;

const SessionImage = styled(Img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
    padding-bottom: 35%;
  
  @media (min-width: 768px) {
      padding-bottom: 0;
    }
`;

const SessionContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

const SessionTitleContainer = styled.div`
  line-height: 1;
  text-transform: uppercase;
`;

const SessionTitle = styled.h2`
  font-family: "Morganite Black", serif;
  font-size: 40px;
  margin-bottom: 0;
  
  @media (min-width: 1100px) {
    font-size: 65px;
  }
`;

const SessionSubtitle = styled.div`
  font-family: "Montserrat Medium", serif;
  font-size: 14px;
  color: #F5F3F0;
  
  @media (min-width: 1100px) {
    font-size: 16px;
  }
  
  span {
    display: block;
    font-family: "Montserrat Bold", serif;
    margin-top: 10px;
  }
`;

const SessionSpeakersContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: "Morganite Black", serif;
  font-size: 30px;
  text-transform: uppercase;
  
  @media (min-width: 1100px) {
    font-size: 42px;
  }
`;

const SessionSpeakersImages = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding-bottom: 15px;
  
  .gatsby-image-wrapper {
    margin-right: 15px;
    width: 50px;
    height: 50px;
    
    @media (min-width: 1100px) {
      width: 80px;
      height: 80px;
    }
  }
`;

const SessionSpeakersNames = styled.div`
  line-height: 1;
`;

const BigSession = styled(Session)`
  @media (min-width: 1100px) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;

const Sessions = ({ data }) => {
  return (
    <Page id="sesje-specjalne" className="page page--dark">
      <PageBackground />
      <PageTitle>
        Sesje specjalne
      </PageTitle>
      <SessionsContainer>
        <Session>
          <SessionImage fluid={data.meskie.childImageSharp.fluid} />
          <SessionContent>
            <SessionTitleContainer>
              <SessionTitle>
                JAK PRZYSTAŁO NA MĘŻCZYZNĘ
              </SessionTitle>
              <SessionSubtitle>
                Sesja specjalna - Piątek
              </SessionSubtitle>
            </SessionTitleContainer>
            <SessionSpeakersContainer>
              <SessionSpeakersImages>
                <Img fluid={data.karolak_circle.childImageSharp.fluid}/>
                <Img fluid={data.maciej_circle.childImageSharp.fluid}/>
              </SessionSpeakersImages>
              <SessionSpeakersNames>
                Karol Michalak + Maciej Maciejewicz
              </SessionSpeakersNames>
            </SessionSpeakersContainer>
          </SessionContent>
        </Session>
        <Session>
          <SessionImage fluid={data.damskie.childImageSharp.fluid} />
          <SessionContent>
            <SessionTitleContainer>
              <SessionTitle>
                DZIELNA KOBIETA - TRUDNO O TAKĄ
              </SessionTitle>
              <SessionSubtitle>
                Sesja specjalna - Piątek
              </SessionSubtitle>
            </SessionTitleContainer>
            <SessionSpeakersContainer>
              <SessionSpeakersImages>
                <Img fluid={data.iga_circle.childImageSharp.fluid}/>
                <Img fluid={data.karolina_circle.childImageSharp.fluid}/>
              </SessionSpeakersImages>
              <SessionSpeakersNames>
                Iga Liziniewicz + Karolina Smogorzewska
              </SessionSpeakersNames>
            </SessionSpeakersContainer>
          </SessionContent>
        </Session>
        <BigSession>
          <SessionImage fluid={data.sesja_specjalna.childImageSharp.fluid} />
          <SessionContent>
            <SessionContent>
              <SessionTitleContainer>
                <SessionTitle>
                  Bóg, który uzdrawia
                </SessionTitle>
                <SessionSubtitle>
                  Sesja specjalna - Sobota
                  <span>Wejście za free</span>
                </SessionSubtitle>
              </SessionTitleContainer>
              <SessionSpeakersContainer>
                <SessionSpeakersImages>
                  <Img fluid={data.zielinski_circle.childImageSharp.fluid}/>
                </SessionSpeakersImages>
                <SessionSpeakersNames>
                  Marcin Zieliński
                </SessionSpeakersNames>
              </SessionSpeakersContainer>
            </SessionContent>
          </SessionContent>
        </BigSession>
      </SessionsContainer>
    </Page>
  );
};

export default Sessions;
