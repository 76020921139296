import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import * as Sentry from '@sentry/browser';
import styled from 'styled-components';
import Img from 'gatsby-image';

import {
  TweenLite, TweenMax, TimelineMax, Linear, Bounce, Power4, Power1, Power2, Power0,
} from 'gsap';

import { MenuContext } from '../components/menu_context';

import Layout from '../components/layout';

import MenuContainer from '../components/pages/menu';

import About from '../components/pages/about';
import Media from '../components/pages/media';
import ContactContent from '../components/pages/contact';
import Speakers from '../components/pages/speakers/index';
import Registration from '../components/pages/registration';
import RegistrationPlaceholder from '../components/pages/registrationPlaceholder';
import Workshops from '../components/pages/workshops';
import Attractions from '../components/pages/attractions';
import Plan from '../components/pages/Plan';
import RegistrationWolo from '../components/pages/registrationWolo';
import Events from "../components/pages/events"
import Sessions from "../components/pages/sessions"

Sentry.init({dsn: 'https://56ccf7a54111496588b70cce24ef6125@sentry.io/1771379'});

export const PageTitle = styled.h1`
  font-family: 'Morganite Black', serif;
  font-size: 60px;
  padding: 35px 35px 20px;
  text-transform: uppercase;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  @media (min-width: 768px) {
    font-size: 180px;
    padding: 45px 35px 10px;
  }
`;

const HeroSection = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: #f5f3f0;
`;

const HeroTitle = styled.h1`
  width: 70px;
  padding-top: 20px;
  //height: 275px;
  //padding-top: 50px;
  line-height: .9;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  font-size: 80px;
  font-family: "Morganite Black", serif;
  color: #F5F3F0;
  text-transform: uppercase;
  text-align: center;
  margin: 0 auto;
  font-variant-numeric: tabular-nums;
  z-index: 2;
  @media (min-width: 768px) {
    width: 90px;
    font-size: 110px;
  }
`;

const HeroLetter = styled.span`
  width: 50%;
`;

const HeroBackground = styled.div`
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
    pointer-events: none;
  -webkit-touch-callout: none; 
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const HeroBackgroundImage = styled(Img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
    pointer-events: none;
  -webkit-touch-callout: none; 
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const HeroImg = styled(Img)`
  position: absolute !important;
  pointer-events: none;
  -webkit-touch-callout: none; 
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const HeroCircle = styled(HeroImg)`
  width: 80vw;
  max-width: 900px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  @media (min-width: 768px) {
    width: 40vw;
  }
`;

const RoslLD = styled(HeroImg)`
  width: 80vw;
  left: -34vw;
  bottom: -18vh;
  z-index: 10;
  @media(min-width: 768px) {
    width: 40vw;
    left: -16vw;
    bottom: -38vh;
  }
`;

const RoslLG = styled(HeroImg)`
  width: 80vw;
  left: -38vw;
  top: -18vh;
  z-index: 10;
  @media(min-width: 768px) {
    width: 40vw;
    left: -18vw;
    top: -38vh;
  }
`;

const RoslLZ = styled(HeroImg)`
  width: 80vw;
  left: -38vw;
  top: 25vh;
  z-index: 9;
  @media(min-width: 768px) {
    width: 59vw;
  left: -36vw;
  top: -13vh;
  }
`;

const RoslPD = styled(HeroImg)`
  width: 80vw;
  right: -28vw;
  bottom: -18vh;
  z-index: 11;
  @media(min-width: 768px) {
    width: 40vw;
    right: -18vw;
    bottom: -38vh;
  }
`;

const RoslPG = styled(HeroImg)`
  width: 80vw;
  right: -34vw;
  top: -15vh;
  z-index: 10;
  @media(min-width: 768px) {
    width: 40vw;
    right: -18vw;
    top: -30vh;
  }
`;

const RoslPZ = styled(HeroImg)`
  width: 80vw;
  right: -34vw;
  bottom: 15vh;
  z-index: 9;
  @media(min-width: 768px) {
    width: 40vw;
    right: -18vw;
    bottom: 10vh;
  }
`;

const WaveLD = styled(HeroImg)`
  width: 80vw;
  left: -35vw;
  bottom: -18vh;
  z-index: 6;
  @media(min-width: 768px) {
    width: 40vw;
    left: -25vw;
    bottom: -50vh;
  }
`;

const WaveLG = styled(HeroImg)`
  width: 80vw;
  left: -38vw;
  bottom: 0vh;
  z-index: 6;
  @media(min-width: 768px) {
    width: 40vw;
    left: -18vw;
    bottom: 5vh;
  }
`;

const WaveLM = styled(HeroImg)`
  width: 80vw;
  left: -38vw;
  bottom: 34vh;
  z-index: 6;
  @media(min-width: 768px) {
    width: 40vw;
    left: -12vw;
    bottom: -18vh;
  }
`;

const WavePD = styled(HeroImg)`
  width: 80vw;
  right: -34vw;
  bottom: -20.5vh;
  z-index: 6;
  @media(min-width: 768px) {
    width: 40vw;
    right: -14vw;
    bottom: -20.5vh;
  }
`;

const WavePG = styled(HeroImg)`
  width: 80vw;
  right: -35vw;
  top: 12vh;
  z-index: 6;
  @media(min-width: 768px) {
    width: 40vw;
    right: -22vw;
    top: 18vh;
  }
`;

const IndexPage = ({ data }) => {

  const [menuState, setMenu] = useState(false);

  const [scrollEvent, setScrollEvent] = useState(false);

  const toggleMenu = () => {
    setMenu(!menuState);
  };
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const sections = document.querySelectorAll('.page');
      const pageSum = document.querySelector('.pages-sum');

      if (pageSum) pageSum.innerHTML = sections.length;
    }
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const animationElementHeroBg = document.querySelector('.hero-bg');
      const animationElementsLeftPlant = document.querySelectorAll('.rosl-l');
      const animationElementsRightPlant = document.querySelectorAll('.rosl-p');
      const animationElementsWaveLeft = document.querySelectorAll('.wave-l');
      const animationElementsWaveRight = document.querySelectorAll('.wave-p');

      const timelineHeroBg = new TimelineMax({ paused: true });
      const timelineLeftPlants = new TimelineMax({ paused: true });
      const timelineRightPlants = new TimelineMax({ paused: true });
      const timelineWaveLeft = new TimelineMax({ paused: true });
      const timelineWaveRight = new TimelineMax({ paused: true });

      animationElementsLeftPlant.forEach((item, index) => {
        timelineLeftPlants.add(TweenMax.to(item, 1, { transform: 'translateX(-20%)' }), `myLabel+=${index / 10}`);
      });

      animationElementsRightPlant.forEach((item, index) => {
        timelineRightPlants.add(TweenMax.to(item, 1, { transform: 'translateX(20%)' }), `myLabel+=${index / 10}`);
      });

      animationElementsWaveLeft.forEach((item, index) => {
        timelineWaveLeft.add(TweenMax.to(item, 1, { transform: 'translateX(-10%)', delay: 0.1 }), `myLabel+=${index / 10}`);
      });

      animationElementsWaveRight.forEach((item, index) => {
        timelineWaveRight.add(TweenMax.to(item, 1, { transform: 'translateX(10%)', delay: 0 }), `myLabel+=${index / 10}`);
      });

      timelineHeroBg.add(TweenMax.to(animationElementHeroBg, 1, { width: '120%', height: '120%' }));

      window.addEventListener('scroll', (e) => {
        setScrollEvent(e);

        const triggerElement = document.querySelector('.page--hero');
        const { scrollY } = window;

        TweenLite.to(timelineHeroBg, 0.3, { progress: scrollY / triggerElement.offsetHeight, ease: Power1.easeOut });
        TweenLite.to(timelineLeftPlants, 0.3, { progress: scrollY / triggerElement.offsetHeight, ease: Power1.easeOut });
        TweenLite.to(timelineRightPlants, 0.3, { progress: scrollY / triggerElement.offsetHeight, ease: Power1.easeOut });
        TweenLite.to(timelineWaveLeft, 0.3, { progress: scrollY / triggerElement.offsetHeight, ease: Power1.easeOut });
        TweenLite.to(timelineWaveRight, 0.3, { progress: scrollY / triggerElement.offsetHeight, ease: Power1.easeOut });
      });
    }
  }, []);

  return (
    <MenuContext.Provider value={{ menuState, toggleMenu }}>
      <Layout data={data} scroll={scrollEvent}>
        <MenuContainer data={data} setMenu={setMenu} />
        <HeroSection className="page page--dark page--hero">
          <HeroTitle className="hero-text">
            <HeroLetter>T</HeroLetter>
            <HeroLetter>w</HeroLetter>
            <HeroLetter>ó</HeroLetter>
            <HeroLetter>r</HeroLetter>
            <HeroLetter>c</HeroLetter>
            <HeroLetter>a</HeroLetter>
          </HeroTitle>
          <HeroBackground>
            <HeroBackgroundImage className="hero-bg" fluid={data.hero_bg.childImageSharp.fluid} />
          </HeroBackground>
          <HeroCircle fluid={data.hero_circle.childImageSharp.fluid} />
          <RoslLG className="rosl-l" fluid={data.rosl_l_g.childImageSharp.fluid} />
          <RoslLZ className="rosl-l" fluid={data.rosl_l_z.childImageSharp.fluid} />
          <RoslLD className="rosl-l" fluid={data.rosl_l_d.childImageSharp.fluid} />
          <RoslPG className="rosl-p" fluid={data.rosl_p_g.childImageSharp.fluid} />
          <RoslPZ className="rosl-p" fluid={data.rosl_p_z.childImageSharp.fluid} />
          <RoslPD className="rosl-p" fluid={data.rosl_p_d.childImageSharp.fluid} />
          <WaveLG className="wave-l" fluid={data.wave_l_g.childImageSharp.fluid} />
          <WaveLD className="wave-l" fluid={data.wave_l_d.childImageSharp.fluid} />
          <WaveLM className="wave-l" fluid={data.wave_l_m.childImageSharp.fluid} />
          <WavePD className="wave-p" fluid={data.wave_p_d.childImageSharp.fluid} />
          <WavePG className="wave-p" fluid={data.wave_p_g.childImageSharp.fluid} />
        </HeroSection>
        <About data={data} />
        <Speakers data={data} />
        <Sessions data={data} />
        <Workshops data={data} />
        <Registration data={data} />
        <RegistrationWolo data={data} />
        <Plan data={data} />
        <Attractions data={data} />
        <Events data={data} />
        <Media data={data} />
        <ContactContent data={data} />
      </Layout>
    </MenuContext.Provider>
  );
};

export const query = graphql`
    query {
        blueImg: file(relativePath: { eq: "img_01.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 600, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bazaFade: file(relativePath: { eq: "baza_fade@x2.png" }) {
            childImageSharp {
                fluid(maxWidth: 300, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bazaFadeBlack: file(relativePath: { eq: "baza_fade_black.png" }) {
            childImageSharp {
                fluid(maxWidth: 300, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bazaFadeBlackDesktop: file(relativePath: { eq: "baza_fade_d@2x.png" }) {
            childImageSharp {
                fluid(maxWidth: 520, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        lava: file(relativePath: { eq: "img_02.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 870, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        lava2: file(relativePath: { eq: "img_03.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 500, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        play: file(relativePath: { eq: "play.png" }) {
            childImageSharp {
                fluid(maxWidth: 26, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        israel: file(relativePath: { eq: "imgyt_04.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 750, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        israel_d: file(relativePath: { eq: "imgyt_04.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 2500, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bcSessions: file(relativePath: { eq: "imgyt_02.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 750, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bcSessions_d: file(relativePath: { eq: "imgyt_02.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 2500, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        BcWs: file(relativePath: { eq: "imgyt_03.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 750, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        BcWs_d: file(relativePath: { eq: "imgyt_03.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 2500, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        BC18_39: file(relativePath: { eq: "imgsq_01.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1000, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        BC18_5: file(relativePath: { eq: "imgsq_02.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 600, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        BC18_36: file(relativePath: { eq: "imgsq_03.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 600, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        BC18_53: file(relativePath: { eq: "imgsq_04.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 600, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        BC18_64: file(relativePath: { eq: "imgsq_05.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 600, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        Flag: file(relativePath: { eq: "imgflag_01.png" }) {
            childImageSharp {
                fluid(maxWidth: 630, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        Flag_d: file(relativePath: { eq: "imgflag_01.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        x: file(relativePath: { eq: "x.png" }) {
            childImageSharp {
                fluid(maxWidth: 15, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        insta: file(relativePath: { eq: "insta@2x.png" }) {
            childImageSharp {
                fluid(maxWidth: 30, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        fb: file(relativePath: { eq: "fb@2x.png" }) {
            childImageSharp {
                fluid(maxWidth: 30, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        yt: file(relativePath: { eq: "yt@2x.png" }) {
            childImageSharp {
                fluid(maxWidth: 30, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        wiktoria: file(relativePath: { eq: "imgspeakerWiktoria.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1000, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        wlodarczyk: file(relativePath: { eq: "imgspeakerWlodarczyl.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1000, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        rene: file(relativePath: { eq: "imgspeakerRene.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1000, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        michalak: file(relativePath: { eq: "imgspeakerMichalak.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1000, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        leobigger: file(relativePath: { eq: "imgspeakerLeoBigger.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1000, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        arek: file(relativePath: { eq: "imgspeakerArkadiusz.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1000, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        reg_1: file(relativePath: { eq: "BC18_Jakub-48@2x.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1000, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        reg_1_mobile: file(relativePath: { eq: "BC18_Jakub-48-2@2x.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1000, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        reg_2: file(relativePath: { eq: "BC18_Jakub-26@2x.png" }) {
            childImageSharp {
                fluid(maxWidth: 1000, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        reg_3: file(relativePath: { eq: "BC18_Jakub-34@2x.png" }) {
            childImageSharp {
                fluid(maxWidth: 1000, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        xCheckBox: file(relativePath: { eq: "xCheckBox@2x.png" }) {
            childImageSharp {
                fluid(maxWidth: 1000, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        regPlaceholder: file(relativePath: { eq: "registrationPlaceholder.png" }) {
            childImageSharp {
                fluid(maxWidth: 4000, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        atr_1: file(relativePath: { eq: "imgstrefa_01.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        atr_2: file(relativePath: { eq: "imgstrefa_02.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        atr_3: file(relativePath: { eq: "imgstrefa_03.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        atr_4: file(relativePath: { eq: "imgstrefa_04.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        slide_arrow: file(relativePath: { eq: "slideArrow@2x.png" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        plan: file(relativePath: { eq: "Plan@2x.png" }) {
            childImageSharp {
                fluid(maxWidth: 2500, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bolt1: file(relativePath: { eq: "bolt1@2x.png" }) {
            childImageSharp {
                fluid(maxWidth: 900, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bolt2: file(relativePath: { eq: "bolt2@2x.png" }) {
            childImageSharp {
                fluid(maxWidth: 900, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        hero_bg: file(relativePath: { eq: "ilu_bg_16x9.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        hero_circle: file(relativePath: { eq: "ilu_circle.png" }) {
            childImageSharp {
                fluid(maxWidth: 980, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        rosl_l_d: file(relativePath: { eq: "Rosl_L_Dol.png" }) {
            childImageSharp {
                fluid(maxWidth: 980, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        rosl_l_g: file(relativePath: { eq: "Rosl_L_Gora.png" }) {
            childImageSharp {
                fluid(maxWidth: 980, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        rosl_l_z: file(relativePath: { eq: "Rosl_L_Zaslonka.png" }) {
            childImageSharp {
                fluid(maxWidth: 1200, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        rosl_p_d: file(relativePath: { eq: "Rosl_P_Dol.png" }) {
            childImageSharp {
                fluid(maxWidth: 1000, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        rosl_p_g: file(relativePath: { eq: "Rosl_P_Gora.png" }) {
            childImageSharp {
                fluid(maxWidth: 1000, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        rosl_p_z: file(relativePath: { eq: "Rosl_P_Zaslonka.png" }) {
            childImageSharp {
                fluid(maxWidth: 1000, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        wave_l_d: file(relativePath: { eq: "Wave_L_Dol.png" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        wave_l_g: file(relativePath: { eq: "Wave_L_Gora.png" }) {
            childImageSharp {
                fluid(maxWidth: 700, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        wave_l_m: file(relativePath: { eq: "Wave_L_Mid.png" }) {
            childImageSharp {
                fluid(maxWidth: 900, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        wave_p_d: file(relativePath: { eq: "Wave_P_Dol.png" }) {
            childImageSharp {
                fluid(maxWidth: 900, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        wave_p_g: file(relativePath: { eq: "Wave_P_Gora.png" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        wave_p_w: file(relativePath: { eq: "Wave_P_Wizja.png" }) {
            childImageSharp {
                fluid(maxWidth: 1000, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        rozanski: file(relativePath: { eq: "Rozanski.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 700, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        wlodarczyk2: file(relativePath: { eq: "Wlodarczyk.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 700, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        krzywodajc: file(relativePath: { eq: "Krzywodajc.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 700, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        leobigger2: file(relativePath: { eq: "LeoBigger.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 700, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        liziniewicz: file(relativePath: { eq: "Liziniewicz.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 700, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        rene2: file(relativePath: { eq: "ReneWagner.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 700, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        michalak2: file(relativePath: { eq: "Michalak.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 700, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        sienna: file(relativePath: { eq: "Sienna@2x.png" }) {
            childImageSharp {
                fluid(maxWidth: 200, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        leaf_contact: file(relativePath: { eq: "Leaf_4@2x.png" }) {
            childImageSharp {
                fluid(maxWidth: 900, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        leaf_contact_2: file(relativePath: { eq: "Leaf_21@2x.png" }) {
            childImageSharp {
                fluid(maxWidth: 900, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        cloud1: file(relativePath: { eq: "Cloud1.png" }) {
            childImageSharp {
                fluid(maxWidth: 700, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        cloud2: file(relativePath: { eq: "Cloud2.png" }) {
            childImageSharp {
                fluid(maxWidth: 900, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        cloud3: file(relativePath: { eq: "Cloud3.png" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        cloud4: file(relativePath: { eq: "Cloud4.png" }) {
            childImageSharp {
                fluid(maxWidth: 550, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        cloud5: file(relativePath: { eq: "Cloud5.png" }) {
            childImageSharp {
                fluid(maxWidth: 950, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        leaf_rej_1: file(relativePath: { eq: "LeafRej_1.png" }) {
            childImageSharp {
                fluid(maxWidth: 950, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        leaf_rej_2: file(relativePath: { eq: "LeafRej_2.png" }) {
            childImageSharp {
                fluid(maxWidth: 700, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        closeRegister: file(relativePath: { eq: "closeRegister.png" }) {
            childImageSharp {
                fluid(maxWidth: 40, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        registrationImg: file(relativePath: { eq: "RegistrationIMG.png" }) {
            childImageSharp {
                fluid(maxWidth: 790, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        regArr: file(relativePath: { eq: "regArr.png" }) {
            childImageSharp {
                fluid(maxWidth: 75, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        plan_mobile: file(relativePath: { eq: "plan_mobile.png" }) {
            childImageSharp {
                fluid(maxWidth: 750, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        wolo_reg: file(relativePath: { eq: "BC18_Jakub-3.png" }) {
            childImageSharp {
                fluid(maxWidth: 790, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        regDeluxBg: file(relativePath: { eq: "DodateDeluxBG.png" }) {
            childImageSharp {
                fluid(maxWidth: 4000, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bird1: file(relativePath: { eq: "Bird1.png" }) {
            childImageSharp {
                fluid(maxWidth: 400, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bird2: file(relativePath: { eq: "Bird2.png" }) {
            childImageSharp {
                fluid(maxWidth: 400, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        leobigger_mobile: file(relativePath: { eq: "Mobile_LeoBigger.png" }) {
            childImageSharp {
                fluid(maxWidth: 600, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        michalak_mobile: file(relativePath: { eq: "Mobile_MIchalak.png" }) {
            childImageSharp {
                fluid(maxWidth: 600, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        rene_mobile: file(relativePath: { eq: "Mobile_Rene.png" }) {
            childImageSharp {
                fluid(maxWidth: 600, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        wiktoria_mobile: file(relativePath: { eq: "Mobile_Wiktoria.png" }) {
            childImageSharp {
                fluid(maxWidth: 600, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        arkadiusz_mobile: file(relativePath: { eq: "Mobile_Arkadiusz.png" }) {
            childImageSharp {
                fluid(maxWidth: 600, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        wolo_arrow: file(relativePath: { eq: "regArr@2x.png" }) {
            childImageSharp {
                fluid(maxWidth: 75, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        atrakcja_11: file(relativePath: { eq: "_0001_atrakcja_11@2x.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        atrakcja_10: file(relativePath: { eq: "_0002_atrakcja_10@2x.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        atrakcja_09: file(relativePath: { eq: "_0003_atrakcja_09@2x.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        atrakcja_08: file(relativePath: { eq: "_0004_atrakcja_08@2x.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        atrakcja_07: file(relativePath: { eq: "_0005_atrakcja_07@2x.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        atrakcja_06: file(relativePath: { eq: "_0006_atrakcja_06@2x.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        atrakcja_05: file(relativePath: { eq: "_0007_atrakcja_05@2x.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        atrakcja_04: file(relativePath: { eq: "_0008_atrakcja_04@2x.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        atrakcja_03: file(relativePath: { eq: "_0009_atrakcja_03@2x.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        atrakcja_02: file(relativePath: { eq: "_0010_atrakcja_02@2x.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        atrakcja_01: file(relativePath: { eq: "_0011_atrakcja_01@2x.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        atrakcja_12: file(relativePath: { eq: "_0000_atrakcja_12@2x.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        widowisko: file(relativePath: { eq: "Widowisko@2x.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        linia_nocna: file(relativePath: { eq: "LiniaNocna@2x.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        meskie: file(relativePath: { eq: "Meskie.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1200, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        damskie: file(relativePath: { eq: "Damskie.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1200, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        sesja_specjalna: file(relativePath: { eq: "sesjaspecjalna.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 2000, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        karolak_circle: file(relativePath: { eq: "karolak.png" }) {
            childImageSharp {
                fluid(maxWidth: 180, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        maciej_circle: file(relativePath: { eq: "Maciej.png" }) {
            childImageSharp {
                fluid(maxWidth: 180, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        iga_circle: file(relativePath: { eq: "Iga.png" }) {
            childImageSharp {
                fluid(maxWidth: 180, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        karolina_circle: file(relativePath: { eq: "Karolina.png" }) {
            childImageSharp {
                fluid(maxWidth: 180, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        zielinski_circle: file(relativePath: { eq: "zielinski.png" }) {
            childImageSharp {
                fluid(maxWidth: 180, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        sobotka: file(relativePath: { eq: "Sobotka.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 700, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        fit: file(relativePath: { eq: "BadzFit.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 700, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        branding: file(relativePath: { eq: "Branding.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 700, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        coffee: file(relativePath: { eq: "CoffeePainting.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 700, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        dens: file(relativePath: { eq: "DensMaSens.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 700, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        foto: file(relativePath: { eq: "Foto.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 700, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        freestyle: file(relativePath: { eq: "FreestyleFootball.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 700, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        skateboarding: file(relativePath: { eq: "Skateboarding.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 700, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        barista: file(relativePath: { eq: "ZostanBarista.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 700, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        taniej: file(relativePath: { eq: "Taniej@2x.png" }) {
            childImageSharp {
                fluid(maxWidth: 2000, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;

export default IndexPage;
