import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import 'cleave.js/dist/addons/cleave-phone.pl';
import Cleave from 'cleave.js/react';
import * as Sentry from '@sentry/browser';
import dayjs from 'dayjs';
import { PageTitle } from './index';

import { RegistrationBox, SmallRegistrationBox } from '../registrationBox';

const Page = styled.div`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  width: 100vw;
  height: 100vh;
  padding: 30px 20px;
  top: 0;
  left: 0;
  background-color: #000;
  color: #F5F3F0;
  z-index: 9999;
  overflow-y: auto;
  @media (min-width: 768px) {
    width: calc(100vw - 70px);
    height: calc(100vh - 70px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px 80px;
  }
`;

const RegisterTitle = styled.div`
  font-family: "Montserrat Medium", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const RegistrationContainer = styled.div`
  width: 100%;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  //justify-content: center;
  //align-items: center;
  //position: absolute;
  //overflow: hidden;
  @media (min-width: 768px) {
    display: ${(props) => (props.visible ? 'grid' : 'none')};
    grid: 1fr / 1fr;
    grid-gap: 550px;
    margin: 0 auto;
    height: 95%;
  }
  @media (min-width: 1350px) {
    grid: 1fr / 2fr 1fr;
    grid-gap: 150px;
  }
`;

const LeftColumn = styled.div`
  padding-top: 50px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const RightColumn = styled.div`
  display: none;
  @media (min-width: 1350px) {
    display: block;
  }
`;

const FormsContainer = styled.div`
  display: grid;
  grid: 1fr / 1fr;
  grid-column-gap: 80px;
  
  @media (min-width: 880px) {
    grid: 1fr / 1fr 1fr;
  }
`;

const PersonalData = styled.div`
  padding-bottom: 30px;
`;

const ContactData = styled.div`
  
`;

const JustInput = styled.input`
  background: #000000;
  width: 100%;
  border: 1px solid #fff;
  max-width: 500px;
  padding: 5px 10px;
  color: #F5F3F0;
  font-family: "Montserrat Medium", sans-serif;
  font-size: 15px;
  margin-bottom: 20px;
  
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #F5F3F0;
    opacity: 1;
  }
  :-ms-input-placeholder {
     color: #F5F3F0;
     opacity: 1;
  }
`;

const OptionCheckBox = styled.div`
  width: 15px;
  height: 15px;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  
  img {
    width: 12px;
    height: 12px;
    padding-left: 1px;
    padding-top: 1px;
  }
`;

const CheckBoxX = styled(Img)`
  display: block;
  position: absolute;
  width: 80%;
  height: 80%;
`;

const ColumnHeader = styled.div`
  font-family: "Morganite Black", sans-serif;
  font-size: 70px;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 20px;
`;

const SexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
`;

const SexOptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #000000;
  border: 1px solid #fff;
  width: 48%;
  max-width: 240px;
  padding: 5px 10px;
  color: #F5F3F0;
  font-family: "Montserrat Medium", sans-serif;
  font-size: 15px;
  margin-bottom: 20px;
  cursor: pointer;
  -webkit-touch-callout: none; 
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
  img {
    display: ${(props) => (props.clicked === props.value ? 'block' : 'none')};
  }
`;

const SexTitle = styled.div`
  
`;

const CleaveInput = styled(Cleave)`
  background: #000000;
  border: 1px solid #fff;
  width: 100%;
  max-width: 500px;
  padding: 5px 10px;
  color: #F5F3F0;
  font-family: "Montserrat Medium", sans-serif;
  font-size: 15px;
  margin-bottom: 20px;
  
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #F5F3F0;
    opacity: 1;
  }
  :-ms-input-placeholder {
     color: #F5F3F0;
     opacity: 1;
  }
`;

const RegisterButton = styled.div`
  background-color: #FD5A47;
  display: flex;
  justify-content: space-between;
  width: 100%;
  //height: 50px;
  padding: 15px 20px;
  cursor: pointer;
  font-family: "Montserrat Medium", sans-serif;
  font-size: 13px;
  line-height: 1;
  text-align: center;
  align-items: center;
  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 1.4;
  }
`;

const LastStepContainer = styled.div`
  
`;

const LastStepTerm = styled.div`
  display: grid;
  grid: 1fr / 30px 1fr;
  margin-bottom: 30px;
  //padding-top: 50px;
  cursor: pointer;
  -webkit-touch-callout: none; 
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
  img {
    display: ${(props) => (props.visible[props.name] ? 'block' : 'none')};
  }
`;

const ConfirmationInfo = styled.div`
  font-family: "Montserrat Bold", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  color: #FD5A47;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 20px;
`;

const LastStepTermText = styled.div`
  font-family: "Montserrat Medium", sans-serif;
  font-size: 13px;
  line-height: 1;
  a {
    text-decoration: none;
    color: #FD5A47;
  }
`;

const Error = styled.div`
  font-family: "Montserrat Medium", sans-serif;
  font-size: 16px;
  line-height: 1;
  justify-self: flex-end;
  color: #FD5A47;
  padding: 40px 0;
`;

const CloseRegisterForm = styled.div`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  width: 20px;
  height: 20px;
  position: fixed;
  top: 40px;
  right: 40px;
  z-index: 99991;
  cursor: pointer;
  @media (min-width: 768px) {
    top: 80px;
    right: 80px;
  }
  @media (min-width: 880px) {
    grid: 1fr / 1fr 1fr;
  }
`;

const RegImg = styled(Img)`
  top: 80px;
  right: 80px;
`;

const TermsDetails = styled.div`
  display: ${(props) => (props.visible ? 'block' : 'none')};
`;

const ShowTerms = styled.span`
  display: ${(props) => (props.visible ? 'unset' : 'none')};
  font-weight: bold;
  font-size: 11px;
`;

const SendingForm = styled.div`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  font-family: "Morganite Black", sans-serif;
  text-transform: uppercase;
  font-size: 60px;
  width: 100%;
  height: 90%;
  align-items: center;
  justify-content: center;
`;

const RegArr = styled.div`
  width: 40px;
  height: 100%;
  //padding: 18px 0;
  //margin-top: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: rotate(${(props) => (props.rotate ? '180deg' : '0')});
  }
  @media (min-width: 768px) {
    //width: 42px;
  }
`;

const RegistrationForm = (props) => {
  const [sending, setSending] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [selectedSex, setSelectedSex] = useState(false);
  const [selectedTerms, setSelectedTerms] = useState({
    media_processing_permission: false,
    data_processing_permission: false,
    terms_accepted: false,
  });

  const [terms, setTerms] = useState(false);
  const [badInputs, setBadInputs] = useState({});

  const handleTermsClick = (name) => {
    setSelectedTerms({
      ...selectedTerms,
      [name]: !selectedTerms[name],
    });
    props.setFormData({
      ...props.formData,
      [name]: !selectedTerms[name],
    });
  };

  const handleChange = (e) => {
    const targetName = e.name || e.target.getAttribute('name');
    const targetValue = e.value || e.target.value;
    if (targetName === 'birthdate') {
      const currentDate = e.target.value.match(/[\w\s]+/gm);
      const validDate = `${currentDate[2]}-${currentDate[1]}-${currentDate[0]}`;
      props.setFormData({
        ...props.formData,
        birthdate: validDate,
      });

      return;
    }
    if (targetName === 'sex') {
      setSelectedSex(targetValue);

      props.setFormData({
        ...props.formData,
        sex: targetValue,
      });

      return;
    }

    props.setFormData({
      ...props.formData,
      [e.target.name]: e.target.value,
    });
  };

  const sendForm = () => {
    const { formData } = props;
    for (const keyData in props.formData) {
      if (formData[keyData] === undefined) {
        props.setFormData({
          ...formData,
          [keyData]: false,
        });
      }
    }
    const data = {
      attendee: props.formData,
    };

    fetch('https://bazacentrum2019.herokuapp.com/attendees', {
      method: 'post',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        Accept: '*/*',
        referer: 'https://bazacentrum2019.herokuapp.com/attendees',
        Connection: 'keep-alive',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (typeof window !== 'undefined') {
          window.location.assign(data.redirect_url);
        }
      })
      .catch((error) => {
        Sentry.captureException(`${error} / ${JSON.stringify(data)}`);
      });
  };

  const validateForm = () => {
    for (const key in props.formData) {
      if ({}.hasOwnProperty.call(props.formData, key)) {
        if (!props.formData[key]) {
          if (key === 'accommodation' || key === 'breakfast' || key === 'dinner' || key === 'deluxe') {
            // console.log(props.formData)
            if (props.formData[key] === undefined) {
              props.setFormData({
                ...props.formData,
                [key]: false,
              });
            }
          } else {
            setBadInputs(badInputs[key] = false);
          }
        }
      }
    }
    if (Object.keys(badInputs).length > 0) {
      let errorsToString = '';
      for (const item in badInputs) {
        errorsToString = `${errorsToString}, ${item}`;
      }
      setErrorMessage('Uzupełnij wszystkie pola i zaznacz wszystkie zgody');
      // setBadInputs({});
    } else {
      setSending(true);
      sendForm();
      setErrorMessage(false);
    }
  };

  const handleRegulationsClick = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <Page visible={props.visible}>
        <RegisterTitle>
        Rejestracja uczestnika
        </RegisterTitle>
        <RegistrationContainer visible={!sending}>
          <LeftColumn>
            <FormsContainer>
              <PersonalData>
                <ColumnHeader>
                Dane osobowe
                </ColumnHeader>
                <JustInput badinputs={badInputs} onChange={(e) => handleChange(e)} name="first_name" placeholder="Imię" />
                <JustInput badinputs={badInputs} onChange={(e) => handleChange(e)} name="last_name" placeholder="Nazwisko" />
                <SexContainer>
                  <SexOptionContainer badinputs={badInputs} value="F" onClick={() => handleChange({ name: 'sex', value: 'F' })} clicked={selectedSex}>
                    <SexTitle>
                    Kobieta
                    </SexTitle>
                    <OptionCheckBox>
                      <CheckBoxX fluid={props.crossImage} />
                    </OptionCheckBox>
                  </SexOptionContainer>
                  <SexOptionContainer badinputs={badInputs} value="M" onClick={() => handleChange({ name: 'sex', value: 'M' })} clicked={selectedSex}>
                    <SexTitle>
                    Mężczyzna
                    </SexTitle>
                    <OptionCheckBox>
                      <CheckBoxX fluid={props.crossImage} />
                    </OptionCheckBox>
                  </SexOptionContainer>
                </SexContainer>
                <CleaveInput
                  badinputs={badInputs}
                  placeholder="Data urodzenia:"
                  name="birthdate"
                  options={{
                    date: true,
                    delimiter: '-',
                    datePattern: ['d', 'm', 'Y'],
                  }}
                  onChange={(e) => handleChange(e)}
                />
                <JustInput badinputs={badInputs} name="city" onChange={(e) => handleChange(e)} placeholder="Miasto" />
              </PersonalData>
              <ContactData>
                <ColumnHeader>
                    Dane kontaktowe
                </ColumnHeader>
                <JustInput badinputs={badInputs} name="email" onChange={(e) => handleChange(e)} type="email" placeholder="Email" />
                <CleaveInput
                  badinputs={badInputs}
                  name="phone"
                  placeholder="Phone"
                  options={{
                    phone: true,
                    phoneRegionCode: 'PL',
                  }}
                  onChange={(e) => handleChange(e)}
                />
                <Error>{errorMessage}</Error>
              </ContactData>
            </FormsContainer>
            <LastStepContainer>
              <LastStepTerm name="media_processing_permission" visible={selectedTerms} onClick={() => handleTermsClick('media_processing_permission')}>
                <OptionCheckBox>
                  <CheckBoxX fluid={props.crossImage} />
                </OptionCheckBox>
                <LastStepTermText>
                Wyrażam zgodę na przesyłanie informacji handlowych za pomocą środków komunikacji elektronicznej w rozumieniu ustawy z dnia 18 lipca 2002 roku o świadczenie usług drogą elektroniczną (Dz.U.2017.1219 t.j.) na podany adres e-mail oraz w formie wiadomości tekstowej sms na podany numer telefonu na temat usług oferowanych przez Kościół Zielonoświątkowy Zbór Stołeczny przy ul. Sienna 68/70 z siedzibą w Warszawie. Zgoda jest dobrowolna i może być w każdej chwili wycofana, klikając w odpowiedni link na końcu wiadomości e-mail. Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem.
                  <ShowTerms
                    visible={!terms}
                    onClick={(e) => {
                      e.stopPropagation();
                      setTerms(true);
                    }}
                  >
                    .. czytaj dalej
                  </ShowTerms>
                  <TermsDetails visible={terms}>
                    <br />
                    <br />
                    Zgodnie z art. 13 ust. 1 i ust. 2 rozporządzenia Parlamentu Europejskiego i Rady (UE) z 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych
                    w związku z przetwarzaniem danych osobowych oraz Regulaminem Kościoła Zielonoświątkowego dotyczącego ochrony danych osobowych
                    z dnia 18 kwietnia 2018 r. informujemy, że:
                    <br />
                    <br />
                    - Administratorem danych osobowych jest: Kościół Zielonoświątkowy Zbór Stołeczny przy ul. Sienna 68/70 z siedzibą w Warszawie, reprezentowany przez Arkadiusza Kuczyńskiego - pastora Zboru Stołecznego.
                    - Przewodniczący KODO pełni funkcję inspektora ochrony danych osobowych; kontakt: (22) 595 18 22, sekretariat@sienna.waw.pl
                    - Dane osobowe przetwarzane będą zgodnie z Regulaminem i zapisami statutowymi.
                    - Twoje dane mogą być przekazywane podmiotom przetwarzającym dane osobowe na zlecenie administratora: Email Labs
                    - Dane osobowe będą przechowywane do momentu zakończenia danego procesu lub znajdą się w archiwum.
                    - Zachowane jest prawo do dostępu do treści swoich danych osobowych, prawo do ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych, prawo do wniesienia sprzeciwu, prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania.
                    - Cofnięcie zgody może nastąpić na podstawie wypełnionego oświadczenia o cofnięciu zgody i wysłanie poczta tradycyjna lub drogą elektroniczną.
                    - Dopuszcza się możliwość złożenia skargi do Przewodniczącego KODO w przypadku uznania naruszenia przepisów Rozporządzenia i Regulaminu.
                    - Podanie danych osobowych jest konieczne do przedstawienia warunków zawartych w formularzu. Nie podanie danych osobowych będzie traktowane, jako zakończenie procesów zawartych w oświadczeniu.
                  </TermsDetails>
                </LastStepTermText>
              </LastStepTerm>
              <LastStepTerm name="data_processing_permission" visible={selectedTerms} onClick={() => handleTermsClick('data_processing_permission')}>
                <OptionCheckBox>
                  <CheckBoxX fluid={props.crossImage} />
                </OptionCheckBox>
                <LastStepTermText>
                Wyrażam zgodę na przetwarzanie moich danych osobowych przez Kościół Zielonoświątkowy Zbór Stołeczny przy ul. Sienna 68/70
                z siedzibą w Warszawie w celach rejestracji na konferencji zgodnie z ustawą z dnia 29 sierpnia 1997 r. o ochronie danych osobowych
                (tekst jedn.: Dz. U. z 2002 r. Nr 101, poz. 926 z późn. zm.) oraz w celach marketingowych.
                </LastStepTermText>
              </LastStepTerm>
              <LastStepTerm name="terms_accepted" visible={selectedTerms} onClick={() => handleTermsClick('terms_accepted')}>
                <OptionCheckBox>
                  <CheckBoxX fluid={props.crossImage} />
                </OptionCheckBox>
                <LastStepTermText>
                Oświadczam, że zapoznałem się z treścią i akceptuję
                  {' '}
                  <a onClick={handleRegulationsClick} href="/regulamin" target="_blank">regulamin</a>
                  {' '}
konferencji Baza Centrum 2019
                </LastStepTermText>
              </LastStepTerm>
              <ConfirmationInfo>
              Potwierdzeniem rejestracji i gwarancją udziału w konferencji jest dokonanie płatności
              </ConfirmationInfo>
              <RegisterButton onClick={validateForm}>
                <RegArr>
                  <Img fluid={props.regArr} />
                </RegArr>
                  Prześlij formularz i przejdź do płatności online - Dotpay
                <RegArr rotate="true">
                  <Img fluid={props.regArr} />
                </RegArr>
              </RegisterButton>
            </LastStepContainer>
          </LeftColumn>
          <RightColumn>
            <RegImg fluid={props.regImg} />
          </RightColumn>
        </RegistrationContainer>
        <SendingForm visible={sending}>
          Wysyłam...
        </SendingForm>
      </Page>
      <CloseRegisterForm visible={props.visible} onClick={() => props.setRegisterForm(false)}>
        <Img fluid={props.closeRegister} />
      </CloseRegisterForm>
    </>
  );
};

export default RegistrationForm;
