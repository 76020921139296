import { Link } from 'gatsby';
import React, { useEffect } from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  position: fixed;
  width: calc(100% - 70px);
  bottom: 0;
  left: 0;
  display: none;
  z-index: 25;
  pointer-events: none;
  @media (min-width: 768px) {
    
    display: flex;
    left: 35px;
    bottom: 35px;
    flex-direction: column;
  }
`;

const SocialIconsContainer = styled.div`
  display: flex;
  left: 35px;
  bottom: 35px;
  flex-direction: column;
  width: 25px;
  pointer-events: auto;
`;

const SocialIconLink = styled.a`
  margin-bottom: 20px;
`;

const SocialIconImage = styled(Img)`
  width: 25px;
  height: 25px;
  display: block;
  position: relative;
  //background-color: #f5f3f0;
`;

const PageIndicatorContainer = styled.div`
  width: 300px;
  height: 300px;
  position: absolute;
  bottom: 0;
  right: 0;
`;

const PageIndicatorHolder = styled.div`
  position: relative;
  height: 100%;
`;

const ActualPage = styled.div`
  position: absolute;
  right: 65px;
  bottom: 130px;
  font-family: "Morganite Black", serif;
  font-size: 180px;
`;

const PageSlash = styled.div`
  position: absolute;
  right: 20px;
  bottom: 160px;
  font-family: "Morganite Light", serif;
  font-size: 180px;
  color: #808080;
  -webkit-text-fill-color: rgba(0,0,0,0);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #808080;
`;

const PagesSum = styled.div`
  position: absolute;
  right: 0;
  bottom: 205px;
  font-family: "Morganite Black", serif;
  font-size: 80px;
  color: #808080;
  -webkit-text-fill-color: rgba(0,0,0,0);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #808080;
`;

const PageIndicatorAddress = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  font-family: "Montserrat Medium", serif;
  font-size: 14px;
  line-height: 1.3;
`;

const Footer = ({ data, scroll }) => {
  let darkPages = false;
  let footer = false;
  let sections = false;
  let pageNumberElement = false;

  if (typeof window !== 'undefined') {
    darkPages = document.querySelectorAll('.page--dark');
    footer = document.querySelector('footer');
    sections = document.querySelectorAll('.page');
    pageNumberElement = document.querySelector('.actual-page-number');

    darkPages = [...darkPages];
    sections = [...sections];

    const { scrollY } = window;
    const windowHeight = window.innerHeight;

    darkPages.some((item) => {
      const elementTop = item.offsetTop;
      const elementHeight = item.offsetHeight;

      if ((scrollY + windowHeight) - 160 >= elementTop && (scrollY + windowHeight) - 160 <= elementTop + elementHeight) {
        if (!footer.classList.contains('bright')) {
          footer.classList.add('bright');
        }
        return true;
      } footer.classList.remove('bright');
    });

    // console.log(sections)

    sections.forEach((item, index) => {
      const elementTop = item.offsetTop;
      const elementHeight = item.offsetHeight;
      if ((scrollY + windowHeight) - 160 >= elementTop && (scrollY + windowHeight) - 160 <= elementTop + elementHeight) {
        pageNumberElement.innerHTML = index + 1;
        return true;
      }
    });
  }

  return (
    <FooterContainer className="bright">
      <SocialIconsContainer>
        <SocialIconLink target="_blank" href="https://www.instagram.com/baza_centrum/">
          <SocialIconImage fluid={data.insta.childImageSharp.fluid} />
        </SocialIconLink>
        <SocialIconLink target="_blank" href="https://www.facebook.com/bazacentrumpl/">
          <SocialIconImage fluid={data.fb.childImageSharp.fluid} />
        </SocialIconLink>
        <SocialIconLink target="_blank" href="https://www.youtube.com/user/bazacentrum">
          <SocialIconImage fluid={data.yt.childImageSharp.fluid} />
        </SocialIconLink>
      </SocialIconsContainer>
      <PageIndicatorContainer>
        <PageIndicatorHolder>
          <ActualPage className="actual-page-number">1</ActualPage>
          <PageSlash>\</PageSlash>
          <PagesSum className="pages-sum">11</PagesSum>
          <PageIndicatorAddress>
            7-10.11.2019
            <br />
            Warszawa
            <br />
            Mińska 65
          </PageIndicatorAddress>
        </PageIndicatorHolder>
      </PageIndicatorContainer>
    </FooterContainer>
  );
};
export default Footer;
