import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import logo from '../images/logo@2x.png';
import menuImg from '../images/menu.png';

import MenuContainer from './pages/menu';

import { MenuContext } from './menu_context';

const HeaderElement = styled.header`
  /* margin-bottom: 75px; */
  -webkit-touch-callout: none; 
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const HeaderLink = styled.a`
  display: none;
  font-family: "Montserrat Medium", serif;
  font-size: 14px;
  color: #808080;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
  @media (min-width: 576px) {
    display: block;
  }
`;

const LinkLogo = styled((props) => <Link {...props} />)`
  max-width: 160px;
  max-height: 18px;
  text-decoration: none;
`;

const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 75px;
  padding: 1.2rem 30px 1.2rem 35px;
  color: #808080;
  z-index: 25;

  @media (min-width: 576px) {
    flex-direction: row;
    height: 110px;
    padding: 1.45rem 50px;
  }
 `;

const MenuTrigger = styled.div`
  position: relative;
  padding: 5px;
  cursor: pointer;
  @media (min-width: 768px) {
    margin-right: 73px;
  }
  &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 44px;
      width: 44px;
      content: "";
      -webkit-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);
  }
 `;


const Header = ({ scroll }) => {
  let darkPages = false;
  let header = false;

  if (typeof window !== 'undefined') {
    darkPages = document.querySelectorAll('.page--dark');
    header = document.querySelector('.page-header');

    // console.log(scroll)

    darkPages = [...darkPages];

    darkPages.some((item) => {
      const { scrollY } = window;
      const elementTop = item.offsetTop;
      const elementHeight = item.offsetHeight;

      if (scrollY >= elementTop - 50 && scrollY <= elementTop - 50 + elementHeight) {
        if (!header.classList.contains('bright')) {
          header.classList.add('bright');
        }
        return true;
      } header.classList.remove('bright');
    });
  }

  return (
    <HeaderElement>
      <HeaderContainer className="page-header bright">
        <MenuContext.Consumer>
          {({ menuState, toggleMenu }) => (
            <MenuTrigger onClick={toggleMenu}>
              <img
                style={{
                  height: '12px',
                }}
                src={menuImg}
                alt="Menu"
              />
            </MenuTrigger>
          )}
        </MenuContext.Consumer>
        <LinkLogo
          to="/#"
        >
          <img
            style={{
              maxWidth: '160px',
            }}
            src={logo}
            alt="Baza Centrum 2019"
          />
        </LinkLogo>
        <HeaderLink href="#rejestracja">
          Zarejestruj się
        </HeaderLink>
      </HeaderContainer>
    </HeaderElement>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle:
    ''
  ,
};

export default Header;
