import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Glide, { Swipe, Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm';

import { PageTitle } from './index';

const Page = styled.div`
  position: relative;
  //color: #F5F3F0;
  padding-bottom: 80px;
`;

const EventsPageTitle = styled(PageTitle)`
  color: #808080;
    @media (min-width: 768px) {
    -webkit-text-fill-color: rgba(0,0,0,0);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #707070;
  }
`;

const EventsContainer = styled.div`
  display: grid;
  grid: 1fr 1fr / 1fr;
  max-width: 1400px;
  margin: 0 auto;
  @media (min-width: 960px) {
    grid: 1fr / 1fr 1fr;
  }
`;

const Event = styled.div`
  position: relative;
  margin: 10px 20px;
  color: #F5F3F0;
`;

const SlideDesc = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  justify-items: flex-start;
  flex-direction: column;
  padding: 35px 50px;
  text-transform: uppercase;
  //backdrop-filter: blur(2px);
`;

const SlideDescTitle = styled.div`
  font-family: 'Morganite Black', serif;
  font-size: 60px;
  //padding-bottom: 10px;
  line-height: 1;
  text-transform: uppercase;
`;

const SlideDescText = styled.div`
  font-family: "Montserrat Medium", serif;
  font-size: 14px;
  line-height: 1.3;
  max-width: 80%;
  @media (min-width: 768px) and (max-width: 1060px) {
    max-width: 100%;
  }
`;

const Events = ({ data }) => {

  return (
    <Page id="wydarzenia" className="page">
      <EventsPageTitle>
        Wydarzenia
      </EventsPageTitle>
      <EventsContainer>
        <Event>
          <Img fluid={data.widowisko.childImageSharp.fluid} alt="Widowisko" />
          <SlideDesc>
            <SlideDescTitle className="title">
              Widowisko
            </SlideDescTitle>
            <SlideDescText>
              Wydarzenie
            </SlideDescText>
          </SlideDesc>
        </Event>
        <Event>
          <Img fluid={data.linia_nocna.childImageSharp.fluid} alt="Widowisko" />
          <SlideDesc>
            <SlideDescTitle className="title">
              Linia Nocna
            </SlideDescTitle>
            <SlideDescText>
              Koncert
            </SlideDescText>
          </SlideDesc>
        </Event>
      </EventsContainer>
    </Page>
  );
};

export default Events;
