import React, { useEffect } from 'react';
import Glide, { Controls, Swipe } from '@glidejs/glide/dist/glide.modular.esm';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { PageTitle } from '../index';

const GlideUl = styled.ul`
  margin: 0;
`;

const GlideSlider = styled.div`
  display: none;
  @media (min-width: 960px) {
    display: block;
    padding-bottom: 80px;
  }
`;

const Slide = styled.li`
  position: relative;
  list-style: none;
  padding-bottom: 20px;
  margin-bottom: 0;
  @media (min-width: 960px) {
    padding-bottom: 0;
    cursor: grab;
  }
`;

const SlideDesc = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  justify-items: flex-start;
  flex-direction: column;
  padding: 35px 50px;
`;

const SlideDescTitle = styled.div`
  font-family: 'Morganite Black', serif;
  font-size: 60px;
  padding-bottom: 10px;
  line-height: 1;
  text-transform: uppercase;
`;

const SlideDescText = styled.div`
  font-family: "Montserrat Medium", serif;
  font-size: 14px;
  line-height: 1.3;
  max-width: 80%;
  @media (min-width: 768px) and (max-width: 1060px) {
    max-width: 100%;
  }
`;

const SliderNav = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GlideBulletsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 0;
`;

const GlideBullet = styled.button`
  border-radius: 50%;
  width: 10px;
  height: 10px;
  padding: 0;
  margin: 0 5px;
  border: none;
  opacity: .33;
  outline: 0;
  cursor: pointer;
`;

const SlideArrowLeft = styled.div`
  position: relative;
  width: 10px;
  height: 16px;
  margin: 0 30px;
  cursor: pointer;
  &:before {
  content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 25px;
  }
`;

const SlideArrowRight = styled(SlideArrowLeft)`
  transform: rotate(180deg);
`;

const SpeakersList = styled.div`
  display: none;
  position: absolute;
  top: 117px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  max-width: 1400px;
  font-family: "Montserrat Medium", serif;
  font-size: 14px;
  letter-spacing: 1px;
  text-align: right;
  padding-right: 35px;
  @media (min-width: 768px) {
    display: block;
  }
`;

const Speaker = ({ name, desc, img }) => (
  <Slide className="glide__slide">
    <Img fluid={img} alt={name} />
    <SlideDesc>
      <SlideDescTitle className="title">
        {name}
      </SlideDescTitle>
      <SlideDescText className="text">
        {desc}
      </SlideDescText>
    </SlideDesc>
  </Slide>
);

let speakersSlider;

const SpeakersSlider = ({ data }) => {
  useEffect(() => {
    speakersSlider = new Glide('.glide__mowcy', {
      type: 'slider',
      perView: 2,
      focusAt: 'center',
      startAt: 0,
    });

    speakersSlider.mount({ Swipe, Controls });

    document.querySelector('.slide-prev').addEventListener('click', () => speakersSlider.go('<'));
    document.querySelector('.slide-next').addEventListener('click', () => speakersSlider.go('>'));
  }, []);

  return (
    <GlideSlider className="glide__mowcy">
      <div data-glide-el="track" className="glide__track">
        <GlideUl className="glide__slides">
          <Speaker
            name="Leo Bigger"
            img={data.leobigger.childImageSharp.fluid}
            desc="Pastor wiodący ICF Zürich i&nbsp;ICF Movement - Międzynarodowej Wspólnoty Chrześcijańskiej, która tworzy Kościoły Ewangelikalne na terenie całej Europy. Misją Leo jest odkrywanie nowych sposobów na zbliżanie ludzi do Boga poprzez transmisję kazań w social mediach, a także wydawanie magazynu pokazującego życie kościoła zza kulis. Razem z żoną Susanną prowadzą program “a new church experience” emitowany w telewizji."
          />
          <Speaker
            name="René Wagner"
            img={data.rene.childImageSharp.fluid}
            desc="Założyciel i&nbsp;główny pastor ICF w Leipzig, Dreźnie i&nbsp;Halle. Z wielką pasją dzieli się Ewangelią w jednym z najbardziej ateistycznych regionów na świecie. Jego inspirujące kazania motywują do życia w zgodzie z biblijnym słowem."
          />
          <Speaker
            name="Wiktoria Maciejewicz"
            img={data.wiktoria.childImageSharp.fluid}
            desc="Jeden z liderów Warszawskiego Składu Soli - spotkań dla młodych ludzi w Zborze Stołecznym KZ Sienna w Warszawie. Razem z mężem odpowiada za organizację Bazy Centrum. Kocha młodych ludzi i&nbsp;w każdym z nich dostrzega talent oraz unikalną wartość. Swoim poświęceniem i&nbsp;miłością do Boga udowadnia, że mając wiarę, wszystko jest możliwe."
          />
          <Speaker
            name="Karol Michalak"
            img={data.michalak.childImageSharp.fluid}
            desc="Pastor kościoła Centrum Chrześcijańskie Zwycięstwo w Łodzi. Warsztatowiec, artysta, absolwent łódzkiej ASP. Nie wstydzi się mówić głośno o tematach trudnych. Szczególnie podkreśla rolę Ducha Świętego w życiu każdego wierzącego."
          />
          <Speaker
            name="Arek Kuczyński"
            img={data.arek.childImageSharp.fluid}
            desc="Pastor Stołecznego Zboru Kościoła Zielonoświątkowego w Warszawie. Zachęca do zmian w myśleniu o Kościele jako instytucji i otwarciu się na kulturę współdziałania. W swoich kazaniach stara się kompleksowo poruszyć każdy temat, biorąc pod uwagę różne punkty widzenia."
          />
        </GlideUl>
      </div>
      <SliderNav>
        <SlideArrowLeft className="slide-prev">
          <Img fluid={data.slide_arrow.childImageSharp.fluid} />
        </SlideArrowLeft>
        <GlideBulletsContainer className="glide__bullets" data-glide-el="controls[nav]">
          <GlideBullet className="glide__bullet" data-glide-dir="=0" />
          <GlideBullet className="glide__bullet" data-glide-dir="=1" />
          <GlideBullet className="glide__bullet" data-glide-dir="=2" />
          <GlideBullet className="glide__bullet" data-glide-dir="=3" />
          <GlideBullet className="glide__bullet" data-glide-dir="=4" />
        </GlideBulletsContainer>
        <SlideArrowRight className="slide-next">
          <Img fluid={data.slide_arrow.childImageSharp.fluid} />
        </SlideArrowRight>
      </SliderNav>
    </GlideSlider>
  );
};

export default SpeakersSlider;
