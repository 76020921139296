import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { PageTitle } from './index';

const Page = styled.div`
    background-color: #121212;
    color: #F5F3F0;
`;

const Movie = styled.a`
    width: 100%;
    height: 145px;
    position: relative;
    text-decoration: none;
    color: #F5F3F0;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.4;
    text-transform: uppercase;
    @media (min-width: 768px) {
        height: 200px;
    }
`;

const MovieContent = styled.div`
    width: 75%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 1;
    @media (min-width: 768px) {
      font-size: 14px;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
`;

const MovieContentButton = styled(Img)`
    width: 26px;
    height: 26px;
    margin-right: 20px;
    margin-bottom: 3px;
`;

const MovieContentText = styled.div`
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
  }
`;

const TextTitle = styled.div`
    font-family: 'Montserrat Black', serif;
    @media (min-width: 768px) {
      font-family: 'Montserrat Medium', serif;
    }
`;

const TextDesc = styled.div`
    font-family: 'Montserrat Medium', serif;
    @media (min-width: 768px) {
      padding-left: 250px;
    }
`;

const MovieCover = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CoverImage = styled(Img)`
    object-fit: cover;
    height: 100%;
    width: 100%;
    //opacity: .7;
    @media (min-width: 768px) {
        display: none;
    }
`;

const CoverImageDesktop = styled(CoverImage)`
    display: none;
    @media (min-width: 768px) {
        display: block;
    }
`;

const Gallery = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: auto calc(25% - 7px) calc(25% - 7px);
    grid-template-rows: calc(50% - 7px) calc(50% - 7px);
    grid-column-gap: 7px;
    grid-row-gap: 7px;
    margin: 50px auto auto;
    max-width: 1230px;
    @media (min-width: 768px) {
        padding-bottom: 80px;
    }
`;

const BigImage = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
`;

const GridImage = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const GalleryImage = styled(Img)`
    object-fit: cover;
    height: 100%;
    width: 100%;
`;


const PromoMovieContainer = styled.div`
 max-width: 1920px;
 margin: 0 auto;
 padding: 0 0 50px 0;
 @media (min-width: 768px) {
  padding: 150px;
 }
`;

const PromoMovie = styled.div`
  width: 100%;
  padding-top: 56.25%;
  margin: 0 auto;
  position: relative;
`;

const PromoIframe = styled.iframe`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Media = ({ data }) => (
  <Page id="media" className="page page--dark">
    <PageTitle>
            Media
    </PageTitle>
    <PromoMovieContainer>
      <PromoMovie>
        <PromoIframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/uPnd41P2nGo"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </PromoMovie>
    </PromoMovieContainer>
    <Movie target="_blank" href="https://www.youtube.com/watch?v=RI9qSo2oAkM">
      <MovieCover>
        <CoverImage fluid={data.israel.childImageSharp.fluid} />
        <CoverImageDesktop fluid={data.israel_d.childImageSharp.fluid} />
      </MovieCover>
      <MovieContent>
        <MovieContentButton fluid={data.play.childImageSharp.fluid} />
        <MovieContentText>
          <TextTitle>Worship 2019</TextTitle>
          <TextDesc>Całe serce (trzymaj mnie)</TextDesc>
        </MovieContentText>
      </MovieContent>
    </Movie>
    <Movie target="_blank" href="https://www.youtube.com/watch?v=NrLGxUFIxjk">
      <MovieCover>
        <CoverImage fluid={data.bcSessions.childImageSharp.fluid} />
        <CoverImageDesktop fluid={data.bcSessions_d.childImageSharp.fluid} />
      </MovieCover>
      <MovieContent>
        <MovieContentButton fluid={data.play.childImageSharp.fluid} />
        <MovieContentText>
          <TextTitle>Worship 2018</TextTitle>
          <TextDesc>Do it again | Zrobisz to jeszcze raz</TextDesc>
        </MovieContentText>
      </MovieContent>
    </Movie>
    <Movie target="_blank" href="https://www.youtube.com/watch?v=6Z0RSkEPJ8w">
      <MovieCover>
        <CoverImage fluid={data.BcWs.childImageSharp.fluid} />
        <CoverImageDesktop fluid={data.BcWs_d.childImageSharp.fluid} />
      </MovieCover>
      <MovieContent>
        <MovieContentButton fluid={data.play.childImageSharp.fluid} />
        <MovieContentText>
          <TextTitle>Worship 2018</TextTitle>
          <TextDesc>You raise me up | Podnosisz mnie</TextDesc>
        </MovieContentText>
      </MovieContent>
    </Movie>
    <Gallery>
      <BigImage>
        <GalleryImage fluid={data.BC18_39.childImageSharp.fluid} />
      </BigImage>
      <GridImage>
        <GalleryImage fluid={data.BC18_5.childImageSharp.fluid} />
      </GridImage>
      <GridImage>
        <GalleryImage fluid={data.BC18_36.childImageSharp.fluid} />
      </GridImage>
      <GridImage>
        <GalleryImage fluid={data.BC18_53.childImageSharp.fluid} />
      </GridImage>
      <GridImage>
        <GalleryImage fluid={data.BC18_64.childImageSharp.fluid} />
      </GridImage>
    </Gallery>
  </Page>
);

export default Media;
