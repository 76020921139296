import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const RegistrationItem = styled.div`
  overflow: hidden;
  margin-bottom: 30px;
  position: relative;
  height: 500px;
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
  @media (min-width: 1070px) {
    margin-bottom: 30px;
  }
`;

const SmallerRegistrationItem = styled(RegistrationItem)`
  height: 240px;
  @media (min-width: 768px) {
    //height: 300px;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  @media (min-width: 1070px) {
    height: 500px;
    grid-column-end: 2;
  }
`;

const RegItemImage = styled(Img)`
  filter: blur(2px) brightness(.5);
  position: absolute;
  width: 105%;
  height: 105%;
  margin: -2.5%;
  object-fit: cover;
`;

const RegItemImageSpecial = styled(Img)`
  filter: blur(2px) brightness(.5);
  position: absolute;
  width: 105%;
  height: 105%;
  margin: -2.5%;
  object-fit: cover;
`;

const SmallRegItemImage = styled(RegItemImageSpecial)`
  display: none;
  @media (min-width: 768px) {
    display: none;
    margin: 0;
  }
  @media (min-width: 1070px) {
    display: block;
    margin: -1.5%;
  }
`;

const RegMobileItemImage = styled(RegItemImageSpecial)`
  display: block;
  //margin: 0;
  @media (min-width: 768px) {
  }
  @media (min-width: 1070px) {
    display: none;
  }
`;

const RegistrationContent = styled.div`
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px 15px;
`;

const TopRegContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopRegContentHeader = styled.div`
  font-family: "Morganite Black", serif;
  font-size: 40px;
  color: #F5F3F0;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  line-height: 1;
`;

const TopRegContentTitle = styled.div`

`;

const TopRegContentPrice = styled.div`
  
`;


const TopRegContentDesc = styled.div`
  font-family: "Montserrat Medium", serif;
  font-size: 12px;
  width: 70%;
  line-height: 1.3;
  color: #F5F3F0;
  padding: 10px 0;
  @media (min-width: 768px) {
    width: 100%;
  }
  
  @media (min-width: 1070px) {
    width: 70%;
  }
`;

const BottomRegContent = styled.div`
  
`;

const BottomRegText = styled(TopRegContentDesc)`
  padding: 10px 0 30px;
`;

const BottomRegOptions = styled.div`
  padding-bottom: 20px;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
`;

const BottomRegSingleOptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  opacity: ${(props) => (props.clicked ? 1 : 0.6)};
  
  img {
    display: ${(props) => (props.clicked ? 'block' : 'none')};
  }
`;

const BottomRegSingleOption = (props) => (
  <BottomRegSingleOptionContainer clicked={props.additives[props.name]} onClick={props.changeAdditives}>
    {props.children}
  </BottomRegSingleOptionContainer>
);

const OptionTitleElement = styled.div`
  font-family: "Montserrat Medium", serif;
  color: #F5F3F0;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const OptionCheckBox = styled.div`
  width: 12px;
  height: 12px;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const CheckBoxX = styled(Img)`
  display: block;
  position: absolute;
  width: 80%;
  height: 80%;
`;


const OptionPrice = styled.div`
  font-family: "Morganite Black", serif;
  color: #F5F3F0;
  font-size: 33px;
`;

const BottomRegSummary = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const RegSummaryButton = styled.div`
  font-family: "Montserrat Medium", serif;
  font-size: 13px;
  color: #F5F3F0;
  background-color: #FD5A47;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
  cursor: pointer;
`;

const RegSummaryPrice = styled.div`
  border-top: 1px solid #fff;
  padding: 20px 5px 0 0;
  text-align: right;
  font-family: "Morganite Black", serif;
  color: #F5F3F0;
  font-size: 40px;
  width: 90px;
`;

const PacketNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 1;
`;

const PacketNameSubtitle = styled.div`
  font-size: 9px;
`;

const OptionTitle = (props) => (
  <OptionTitleElement>
    {props.children}
  </OptionTitleElement>
);


export const RegistrationBox = (props) => {
  const packPrice = {
    full: 120,
    fullExtra: 150,
  };

  const additivesPrices = {
    breakfast: 14,
    dinner: 30,
    full: 40,
    deluxe: 30,
  };

  const [additives, setAdditives] = useState({
    breakfast: false,
    dinner: false,
    full: false,
    deluxe: false,
  });

  const getAdditivesForAPI = () => {
    if (additives.full) {
      return {
        ...props.formData,
        deluxe: additives.deluxe,
        breakfast: true,
        dinner: true,
      };
    }

    return {
      ...props.formData,
      deluxe: additives.deluxe,
      breakfast: additives.breakfast,
      dinner: additives.dinner,
    };
  };

  useEffect(() => {
    props.setMainPrice({
      ...props.mainPrice,
      [props.regElement]: packPrice[props.regElement],
    });
    let calculatedPrice = packPrice[props.regElement];
    for (const item in additives) {
      if (additives[item]) {
        calculatedPrice += additivesPrices[item];
      }
    }
    props.setMainPrice({
      ...props.mainPrice,
      [props.regElement]: calculatedPrice,
    });
  }, [additives]);


  const changeAdditives = (name) => {
    if (name === 'deluxe') {
      setAdditives({
        ...additives,
        [name]: !additives[name],
      });
    } else {
      setAdditives({
        deluxe: additives.deluxe,
        [name]: !additives[name],
      });
    }
  };

  const handleRegisterClick = (e) => {
    if (props.type === 'f') {
      props.setFormData({
        ...getAdditivesForAPI(),
        accommodation: false,
      });
    } else {
      props.setFormData({
        ...getAdditivesForAPI(),
        accommodation: true,
      });
    }

    props.changeFormState(true);
  };

  return (
    <RegistrationItem>
      <RegItemImage fluid={props.fluid} />
      <RegistrationContent>
        <TopRegContent>
          <TopRegContentHeader>
            <TopRegContentTitle>
              {props.name}
            </TopRegContentTitle>
            <TopRegContentPrice>
              {props.price}
,00
            </TopRegContentPrice>
          </TopRegContentHeader>
          <TopRegContentDesc>
            {props.desc}
          </TopRegContentDesc>
        </TopRegContent>
        <BottomRegContent>
          <BottomRegText>
            {props.bottomText}
          </BottomRegText>
          <BottomRegOptions>
            <BottomRegSingleOption
              name="breakfast"
              additives={additives}
              changeAdditives={() => changeAdditives('breakfast')}
            >
              <OptionTitle>
                <OptionCheckBox>
                  <CheckBoxX fluid={props.checkbox} />
                </OptionCheckBox>
                <PacketNameContainer>
                  Śniadania
                  <PacketNameSubtitle>
                    Piątek i sobota
                  </PacketNameSubtitle>
                </PacketNameContainer>
              </OptionTitle>
              <OptionPrice>
                +14,00
              </OptionPrice>
            </BottomRegSingleOption>
            <BottomRegSingleOption
              name="dinner"
              additives={additives}
              changeAdditives={() => changeAdditives('dinner')}
            >
              <OptionTitle>
                <OptionCheckBox>
                  <CheckBoxX fluid={props.checkbox} />
                </OptionCheckBox>
                <PacketNameContainer>
                  Obiady
                  <PacketNameSubtitle>
                    Piątek i sobota
                  </PacketNameSubtitle>
                </PacketNameContainer>
              </OptionTitle>
              <OptionPrice>
                +30,00
              </OptionPrice>
            </BottomRegSingleOption>
            <BottomRegSingleOption
              name="full"
              additives={additives}
              changeAdditives={() => changeAdditives('full')}
            >
              <OptionTitle>
                <OptionCheckBox>
                  <CheckBoxX fluid={props.checkbox} />
                </OptionCheckBox>
                <PacketNameContainer>
                  Śniadania i obiady (taniej o 10%)
                  <PacketNameSubtitle>
                    Piątek i sobota
                  </PacketNameSubtitle>
                </PacketNameContainer>
              </OptionTitle>
              <OptionPrice>
                +40,00
              </OptionPrice>
            </BottomRegSingleOption>
            <BottomRegSingleOption
              name="deluxe"
              additives={additives}
              changeAdditives={() => changeAdditives('deluxe')}
            >
              <OptionTitle>
                <OptionCheckBox>
                  <CheckBoxX fluid={props.checkbox} />
                </OptionCheckBox>
                <PacketNameContainer>
                  Dodatek Delux
                  <PacketNameSubtitle>
                    Do odbioru na miejscu konferencji
                  </PacketNameSubtitle>
                </PacketNameContainer>
              </OptionTitle>
              <OptionPrice>
                +30,00
              </OptionPrice>
            </BottomRegSingleOption>
          </BottomRegOptions>
          <BottomRegSummary>
            <RegSummaryButton onClick={(e) => handleRegisterClick(e)}>
              Dokonaj rejestracji
            </RegSummaryButton>
            <RegSummaryPrice>
              {props.mainPrice[props.regElement]}
,00
            </RegSummaryPrice>
          </BottomRegSummary>
        </BottomRegContent>
      </RegistrationContent>
    </RegistrationItem>
  );
};

export const SmallRegistrationBox = (props) => (
  <SmallerRegistrationItem>
    <SmallRegItemImage fluid={props.fluid} />
    <RegMobileItemImage fluid={props.fluidMobile} />
    <RegistrationContent>
      <TopRegContent>
        <TopRegContentHeader>
          <TopRegContentTitle>
            {props.name}
          </TopRegContentTitle>
          <TopRegContentPrice>
              {props.price},00
          </TopRegContentPrice>
        </TopRegContentHeader>
        <TopRegContentDesc>
            {props.desc}
        </TopRegContentDesc>
      </TopRegContent>
    </RegistrationContent>
  </SmallerRegistrationItem>
);
