import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { PageTitle } from './index';

import { RegistrationBox, SmallRegistrationBox } from '../registrationBox';
import RegistrationForm from './registrationForm';

const Page = styled.div`
    background-color: #F5F3F0;
    color: #707070;
`;

const RegisterPageTitle = styled(PageTitle)`
  color: #707070;
  @media (min-width: 768px) {
    -webkit-text-fill-color: rgba(0,0,0,0);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #707070;
  }
`;

const RegistrationContainer = styled.div`
  width: 100%;
  //display: flex;
  //justify-content: center;
  //align-items: center;
  //position: absolute;
  //overflow: hidden;
  @media (min-width: 768px) {
    display: grid;
    grid: 1fr / 1fr 1fr;
    grid-gap: 20px;
    max-width: 1330px;
    margin: 0 auto;
    padding: 0 50px 100px;
  }
  @media (min-width: 1070px) {
    display: grid;
    grid: 1fr / 1fr 1fr 1fr;
    grid-gap: 20px;
    max-width: 1330px;
    margin: 0 auto;
    padding: 0 50px 100px;
  }
`;

const RegDeluxContainer = styled.div`
  position: relative;
  margin-bottom: 100px;
`;

const RegDelux = styled.div`
  position: relative;
  display: flex;
  width: 80%;
  
  max-width: 1280px;
  background-color: #EBDDC8;
  margin: 0 auto;
  align-items: center;
  color: #F79065;
  z-index: 2;
  flex-direction: column;
  padding: 60px 0;
  @media (min-width: 768px) {
    flex-direction: row;
    height: 180px;
    padding: 0;
  }
`;

const RegLess = styled(RegDelux)`
  background-color: #fd5a47;
  color: #fff;
`;

const RegDeluxTitle = styled.div`
  font-size: 40px;
  text-transform: uppercase;
  line-height: 1;
  font-family: "Montserrat Black", sans-serif;
  font-style: italic;
  padding: 0 30px 0;
  @media (min-width: 960px) {
    padding: 0 0 0 60px;
    font-size: 50px;
  }
`;

const RegLessTitle = styled(RegDeluxTitle)`
  width: 100%;
`;

const RegDeluxDesc = styled.div`
  font-family: "Montserrat Medium", sans-serif;
  padding: 40px 30px 0;
  font-size: 16px;
  @media (min-width: 768px) {
    padding: 0 60px;
  }
`;

const RegDeluxBg = styled.div`
  position: absolute;
  width: 120%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 0;
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;

const RegDeluxBird = styled.div`
  position: absolute;
  width: 200px;
  top: -70px;
  right: -140px;
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;

const RegDeluxBird2 = styled.div`
 position: absolute;
 width: 100px;
 bottom: -30px;
 left: -75px;
`;

const LessInfoContainer = styled.div`
  width: 80%;
  max-width: 1280px;
  margin: 0 auto 100px;
`;

const Registration = ({ data }) => {
  const [price, setPrice] = useState({
    full: 120,
    fullExtra: 150,
  });

  const [formData, setFormData] = useState({
    first_name: false,
    last_name: false,
    sex: false,
    birthdate: false,
    city: false,
    email: false,
    phone: false,
    accommodation: false,
    deluxe: false,
    breakfast: false,
    dinner: false,
    data_processing_permission: false,
    media_processing_permission: false,
    terms_accepted: false,
  });

  const [registerForm, setRegisterForm] = useState(false);

  return (
    <Page id="rejestracja" className="page">
      <RegisterPageTitle>
        Kup bilet
      </RegisterPageTitle>
      <RegistrationContainer>
        <SmallRegistrationBox
          name="Wejściówka"
          price={80}
          desc={'Jednodniowa wejściówka\n'
          + 'do kupienia na miejscu\n'
          + 'podczas konferencji.'}
          fluid={data.reg_1.childImageSharp.fluid}
          fluidMobile={data.reg_1_mobile.childImageSharp.fluid}
        />
        <RegistrationBox
          name="Pakiet Full"
          type="f"
          price={150}
          desc={'Uczestnictwo w całej konferencji\n'
          + 'bez noclegu na hali.'}
          bottomText="Jeśli chcesz możesz dodać do pakietu zestaw śniadań i/lub obiadów."
          fluid={data.reg_2.childImageSharp.fluid}
          checkbox={data.xCheckBox.childImageSharp.fluid}
          regElement="full"
          mainPrice={price}
          setMainPrice={setPrice}
          formData={formData}
          setFormData={setFormData}
          changeFormState={setRegisterForm}
        />
        <RegistrationBox
          name="Pakiet Full + Nocleg"
          type="fn"
          price={180}
          desc={'Uczestnictwo w całej konferencji\n'
          + 'wraz z  noclegiem na hali sportowej\n'
          + '500m od miejsca konferencji.'}
          bottomText="Jeśli chcesz możesz dodać do pakietu zestaw śniadań i/lub obiadów."
          fluid={data.reg_3.childImageSharp.fluid}
          checkbox={data.xCheckBox.childImageSharp.fluid}
          regElement="fullExtra"
          mainPrice={price}
          setMainPrice={setPrice}
          formData={formData}
          setFormData={setFormData}
          changeFormState={setRegisterForm}
        />
      </RegistrationContainer>
      <RegistrationForm regArr={data.regArr.childImageSharp.fluid} regImg={data.registrationImg.childImageSharp.fluid} closeRegister={data.closeRegister.childImageSharp.fluid} visible={registerForm} setRegisterForm={setRegisterForm} crossImage={data.xCheckBox.childImageSharp.fluid} formData={formData} setFormData={setFormData} />
      <RegDeluxContainer>
        <RegLess>
          <RegLessTitle>
            Taniej przez internet
          </RegLessTitle>
          <RegDeluxDesc>
            Internetowa sprzedaż biletów ba Bazę Centrum trwa do końca października. Po tym czasie bilet będzie można kupić w dniu konferencji w wyższej cenie.<br/>
            <strong>Skorzystaj z promocji i kup bilet przez internet!</strong>
          </RegDeluxDesc>
        </RegLess>
      </RegDeluxContainer>
      <RegDeluxContainer>
        <RegDelux>
          <RegDeluxTitle>
            Dodatek Delux
          </RegDeluxTitle>
          <RegDeluxDesc>
            To nie lada gratka dla najwierniejszych fanów konferencji. Zrób sobie luksus
            i zarezerwuj sobie pakiet wyjątkowych gadżetów, które będziesz mógł odebrać
            na konferencji. Tym samym wspomagasz dodatkowo organizację Bazy Centrum.
          </RegDeluxDesc>
          <RegDeluxBird>
            <Img fluid={data.bird1.childImageSharp.fluid} />
          </RegDeluxBird>
          <RegDeluxBird2>
            <Img fluid={data.bird2.childImageSharp.fluid} />
          </RegDeluxBird2>
        </RegDelux>
        <RegDeluxBg>
          <Img fluid={data.regDeluxBg.childImageSharp.fluid} />
        </RegDeluxBg>
      </RegDeluxContainer>
    </Page>
  );
};

export default Registration;
