import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

const Page = styled.div`
    background-color: #f5f3f0;
    padding-bottom: 120px;
    @media (min-width: 768px) {
        padding-top: 100px;
    }
`;

const PlaceInfoHeader = styled.div`
  width: 100%;
  text-align: center;
  padding: 90px 30px 80px;
  font-family: "Morganite Black";
  text-transform: uppercase;
  font-size: 12vw;
  color: #808080;
  -webkit-text-fill-color: rgba(0,0,0,0);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #808080;
  display: none;
  @media (min-width: 768px) {
    padding: 140px 30px 80px;
    display: block;
  }
`;

const PlaceInfoHeaderMobile = styled(PlaceInfoHeader)`
  font-size: 17vw;
  text-align: left;
  display: block;
  line-height: 1;
    @media (min-width: 768px) {
    display: none;
  }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 0 10px;
    max-width: 1230px;
    position: relative;
    @media (min-width: 768px) {
        flex-direction: row;
        margin: 70px auto;
    }
`;

const Text = styled.div`
    width: 100%;
    padding: 0 35px 20px;
    font-family: "Montserrat Medium", serif;
    font-size: 12px;
    line-height: 1.3;
    @media (min-width: 768px) {
        width: 60%;
        font-size: 18px;
    }
`;

const TextContainerRight = styled(TextContainer)`
    @media (min-width: 768px) {
        justify-content: flex-end;
        flex-direction: column;
        margin: 150px auto 0;
    }
`;

const TextRight = styled(Text)`
    @media (min-width: 768px) {
        width: 100%;
        padding-left: 43.4%;
        justify-self: flex-end;
    }
`;

const ParagraphText = styled.span`
    padding-bottom: 20px;
    display: block;
`;

const Heading = styled.h2`
    padding: 35px 35px 10px;
    margin: 0;
    color: #808080;
    font-family: "Morganite Black", serif;
    font-size: 47px;
    @media (min-width: 768px) {
        font-size: 110px;
        max-width: 900px;
        margin: 0 auto;
        padding: 35px 35px 150px;
    }
`;

const HeadingRight = styled(Heading)`
    @media (min-width: 768px) {
        margin: 50px auto;
        margin-left: 40%;
        font-size: 100px;
        padding: 35px 35px 0;
    }
`;

const ImageContainer = styled.div`
    margin-bottom: 35px;
    @media (min-width: 768px) {
        display: none;
    }
`;

const BazaFade = styled(Img)`
    position: absolute !important;
    width: 225px;
    margin-left: 35px;
    margin-top: -155px;
`;

const BazaFadeBlackContainer = styled.div`
    display: none;
    width: 100%;
    max-width: 1230px;
    margin: 0 auto;
    padding: 0 35px 20px;
    @media (min-width: 768px) {
        display: block;
    }
`;

const BazaFadeBlack = styled(Img)`
    width: 40%;
`;

const BlueImg = styled(Img)`
    display: none;
    position: absolute !important;
    width: 600px;
    height: 400px;
    left: 85%;
    bottom: 150px;
    @media (min-width: 768px) {
        display: block;
    }
`;

const LavaImage = styled(Img)`
    display: none;
    position: absolute !important;
    width: 870px;
    top: 0;
    right: 70%;
    @media (min-width: 768px) {
        display: block;
    }
`;

const HeroImg = styled(Img)`
  position: absolute !important;
  pointer-events: none;
  -webkit-touch-callout: none; 
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const WavePW = styled(HeroImg)`
  width: 80vw;
  right: -52vw;
  top: -8vh;
  z-index: 6;
  @media(min-width: 768px) {
    width: 40vw;
    right: -38vw;
    top: -14vh;
  }
`;

const About = ({ data }) => (
  <Page id="tworca" className="page">
    <ImageContainer>
      <Img fluid={data.blueImg.childImageSharp.fluid} />
      <BazaFade fluid={data.bazaFade.childImageSharp.fluid} />
    </ImageContainer>
    <BazaFadeBlackContainer>
      <BazaFadeBlack fluid={data.bazaFadeBlackDesktop.childImageSharp.fluid} />
    </BazaFadeBlackContainer>
    <TextContainer>
      <Text>
            3 dni, wiele historii i&nbsp;setki młodych osób z całego kraju pragnących doświadczać Boga. Baza Centrum to wydarzenie, które od wielu lat zmienia ludzi, przekazując niezmienną treść Ewangelii. Słowo, które&nbsp;inspiruje do działania, uwielbienie, które daje radość i&nbsp;autentyczna modlitwa, która łączy z Bogiem - to jest właśnie Baza Centrum.
      </Text>
      <BlueImg fluid={data.blueImg.childImageSharp.fluid} />
    </TextContainer>
    <PlaceInfoHeader>7-10.11.2019 Warszawa Mińska 65</PlaceInfoHeader>
    <PlaceInfoHeaderMobile>7-10.11.2019<br />Warszawa Mińska 65</PlaceInfoHeaderMobile>
    <TextContainerRight>
      <HeadingRight>
            TWÓRCA
      </HeadingRight>
      <TextRight>
        <ParagraphText>
                    Bóg artysta, kreator świata i&nbsp;człowieka, pierwszego i&nbsp;jednocześnie najważniejszego dzieła sztuki. Wszystko zostało stworzone przez Niego i&nbsp;dla Niego, a On sam objawia się w stworzeniu od początku świata.
        </ParagraphText>
        <ParagraphText>
                    Jest źródłem wody, które przynosi orzeźwienie i&nbsp;daje życie. Jest jak trawiący ogień, który oczyszcza i&nbsp;przemienia. Przychodzi w lekkim powiewie wiatru i&nbsp;objawia się w codzienności. Stworzył człowieka z prochu ziemi i&nbsp;ożywił swoim tchnieniem.
        </ParagraphText>
        <ParagraphText>
                    Dał nam moc aby ożywiać, siejąc miłość tam, gdzie jej brak. Dzięki&nbsp;Niemu możemy kreować rzeczywistość, która nas otacza i&nbsp;tworzyć pod Jego natchnieniem, dodając wartości każdemu człowiekowi. Mając dostęp do największego źródła inspiracji, my także jesteśmy twórcami.
        </ParagraphText>
      </TextRight>
      <WavePW fluid={data.wave_p_w.childImageSharp.fluid} />
      <LavaImage fluid={data.lava.childImageSharp.fluid} />
    </TextContainerRight>
  </Page>
);

export default About;
