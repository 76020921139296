import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { PageTitle } from './index';

const Page = styled.div`
  position: relative;
  background-color: #121212;
  color: #F5F3F0;
`;

const ContactContent = styled.div`
    margin: auto;
    font-family: 'Montserrat Medium', serif;
    font-size: 12px;
    padding: 0 35px;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    @media (min-width: 768px) {
        max-width: 1230px;
        font-size: 16px;
        flex-direction: row;
    }
`;

const ContactForm = styled.div`
  z-index: 10;
    @media (min-width: 768px) {
        width: 50%;
    }
`;

const AddressContainer = styled.div`
    @media (min-width: 768px) {
        width: 50%;
        text-align: right;
        position: relative;
    }
`;

const AdressImage = styled(Img)`
    position: absolute !important;
    width: 500px;
    height: 300px;
    top: -210px;
    right: -180px;
    display: none;
    @media (min-width: 768px) {
        display: block;
    }
`;

const FormText = styled.div`
    max-width: 400px;
    margin-bottom: 30px;
    font-size: 16px;
`;

const FormTextTopSpace = styled(FormText)`
    //margin-top 150px;
    margin-bottom: 150px;
`;

const ContactMail = styled.a`
    color: #F5F3F0;
    text-decoration: none;
    font-family: 'Montserrat Bold',serif;
`;

const ContactAddress = styled.h2`
    font-family: 'Montserrat Bold',serif;
    font-size: 35px;
    color: #F5F3F0;
    -webkit-text-fill-color: rgba(0,0,0,0);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #F5F3F0;
    margin-top: 50px;
    position: relative;
    z-index: 3;
    @media (min-width: 768px) {
        font-size: 45px;
        padding: 0 20px;
    }
    @media (min-width: 1100px) {
        font-size: 66px;
        padding: 0 35px;
    }
`;

const FlagContainer = styled.div`
    width: 100%;
    height: 50vw;
    overflow: hidden;
    position: relative;
    padding-top: 30px;
    margin: auto;
    @media (min-width: 768px) {
        height: 30vw;
    }
`;

const Flag = styled(Img)`
    width: 90%;
    margin: 0 auto;
    /* top: -50px; */
    max-width: 1230px;
    z-index: 3;
    @media (min-width: 768px) {
        display: none;
    }
`;

const FlagBg = styled.div`
    width: 100vw;
    height: 60px;
    background: #fff;
    position: absolute;
    bottom: 0;
    @media (min-width: 768px) {
        height: 10vw;
    }
`;

const FlagDesktop = styled(Flag)`
    display: none;
    @media (min-width: 768px) {
        display: block;
    }
`;

const OrganizerHeader = styled.div`
  font-family: "Morganite Black", sans-serif;
  text-transform: uppercase;
  font-size: 60px;
  margin-top: 40px;
  @media (min-width: 768px) {
    font-size: 80px;
  }
`;

const ContactHeader = styled(OrganizerHeader)`
  margin-top: 150px;
`;

const OrganizerLogo = styled(Img)`
  width: 100px;
  margin: -10px 0 10px;
`;

const FormTextColumns = styled.div`
  display: grid;
  grid: 1fr / 1fr;
  margin-bottom: 100px;
  @media (min-width: 768px) {
   grid: 1fr / 1fr 1fr;
  }
`;

const ContactIlu = styled(Img)`
  position: absolute !important;
  pointer-events: none;
  -webkit-touch-callout: none; 
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const Leaf1 = styled(ContactIlu)`
  width: 80vw;
  max-width: 900px;
  bottom: 14vh;
  left: -32vw;
  z-index: 1;
  @media (min-width: 768px) {
    width: 30vw;
    bottom: 14vh;
    left: -12vw;
  }
`;

const Leaf2 = styled(ContactIlu)`
  width: 80vw;
  max-width: 900px;
  bottom: 70vh;
  right: -32vw;
  z-index: 1;
  @media (min-width: 768px) {
    width: 40vw;
    bottom: 30vh;
    right: -12vw;
  }
`;

const Contact = ({ data }) => (
  <Page id="kontakt" className="page page--dark">
    <PageTitle>
            Kontakt
    </PageTitle>
    <ContactContent>
      <ContactForm>
        <OrganizerHeader>
          Główny organizator
        </OrganizerHeader>
        <FormText>
          Kościół Zielonoświątkowy Zbór Stołeczny Sienna we współpracy z innymi wspólnotami i&nbsp;organizacjami chrześcijańskimi w Polsce
        </FormText>
        <OrganizerLogo fluid={data.sienna.childImageSharp.fluid} />
        <ContactHeader>
          Skontaktuj się
        </ContactHeader>
        <FormTextTopSpace>
                    Masz pytania, na które nie ma odpowiedzi na naszej stronie? Zadaj je, wysyłając maila:
          <ContactMail href="mailto:kontakt@bazacentrum.pl"> kontakt@bazacentrum.pl</ContactMail>
        </FormTextTopSpace>
        <ContactHeader>
          Dane do przelewu
        </ContactHeader>
        <FormTextTopSpace>
          Kościół Zielonoświątkowy Zbór Stołeczny<br/>
          ul. Sienna 68/70, 00-825 Warszawa<br/>
          49 1020 1097 0000 7102 0279 4212
        </FormTextTopSpace>
        <OrganizerHeader>
          Adresy
        </OrganizerHeader>
        <FormTextColumns>
          <FormText>
            Miejsce wydarzenia:
            <br />
            <strong>
            ul. Mińska 65
              <br />
            Warszawa
            </strong>
          </FormText>
          <FormText>
            Miejsce noclegu:
            <br />
            <strong>
            Hala sportowa OSIR „Siennicka“
              <br />
            ul. Siennicka 40 Warszawa
            </strong>
          </FormText>
        </FormTextColumns>
      </ContactForm>
      <AddressContainer>
        <AdressImage fluid={data.lava2.childImageSharp.fluid} />
        <ContactAddress>
                    7-10.11.2019
          <br />
                    WARSZAWA
          <br />
                    MIŃSKA 65
        </ContactAddress>
      </AddressContainer>
      <Leaf1 fluid={data.leaf_contact.childImageSharp.fluid} />
      <Leaf2 fluid={data.leaf_contact_2.childImageSharp.fluid} />
    </ContactContent>
    <FlagContainer>
      <Flag fluid={data.Flag.childImageSharp.fluid} />
      <FlagDesktop fluid={data.Flag_d.childImageSharp.fluid} />
      <FlagBg />
    </FlagContainer>

  </Page>
);

export default Contact;
