import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { PageTitle } from './index';

const Page = styled.div`
  position: relative;
  background-color: #121212;
  color: #F5F3F0;
  padding: 0 0 150px 0;
  @media (min-width: 768px) {
    margin: 0 20px;
  }
`;

const PlanImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0 100px;
  
  @media (min-width: 768px) {
    padding: 100px 0 200px;
  }
`;

const PlanImage = styled(Img)`
display: none;
  width: calc(100vw - 70px);
  max-width: 1500px;
  img {
    user-drag: none; 
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  @media (min-width: 768px) {
    display: block;
  }
`;

const PlanImageMobile = styled(Img)`
  display: block;
  width: 100%;
  img {
    user-drag: none; 
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

const DescriptionsContainer = styled.div`
  display: grid;
  grid: 1fr 1fr / 1fr;
  grid-gap: 10px;
  margin: 0 10px;
  color: #F5F3F0;
  max-width: 1000px;
  @media (min-width: 768px) {
    grid: 1fr 1fr / 1fr 1fr;
    margin: 0 auto;
  }
`;

const DescriptionBox = styled.div`
  padding: 20px;
  
  @media (min-width: 768px) {
    padding: 40px;
    background-color: rgba(245, 243, 240, .33);
  }
`;

const DescriptionTitle = styled.div`
  font-family: "Morganite Black", sans-serif;
  text-transform: uppercase;
  font-size: 60px;
  line-height: 1;
`;

const DescriptionText = styled.div`
  font-family: "Montserrat Medium", sans-serif;
  font-size: 14px;
  line-height: 1.3;
`;

const Bolt1 = styled.div`
  position: absolute;
  width: 460px;
  height: 1000px;
  bottom: 0;
  left: -110px;
  img {
    user-drag: none; 
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;

const Bolt2 = styled.div`
  position: absolute;
  width: 920px;
  height: 1100px;
  top: -170px;
  right: -190px;
  img {
    user-drag: none; 
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;

const Plan = ({ data }) => (
  <Page id="plan" className="page page--dark">
    <PageTitle>
      Plan konferencji
    </PageTitle>
    <Bolt1>
      <Img fluid={data.bolt1.childImageSharp.fluid} />
    </Bolt1>
    <Bolt2>
      <Img fluid={data.bolt2.childImageSharp.fluid} />
    </Bolt2>
    <PlanImageContainer>
      <PlanImage fluid={data.plan.childImageSharp.fluid} />
      <PlanImageMobile fluid={data.plan_mobile.childImageSharp.fluid} />
    </PlanImageContainer>
    <DescriptionsContainer>
      <DescriptionBox>
        <DescriptionTitle>
          Poranne inspiracje
        </DescriptionTitle>
        <DescriptionText>
          Spotkanie inspiracyjne prowadzone przez jednego
          z naszych gości, z przestrzenią na samodzielną modlitwę, uwielbienie i studiowanie Biblii.
        </DescriptionText>
      </DescriptionBox>
      <DescriptionBox>
        <DescriptionTitle>
          Sesje główne
        </DescriptionTitle>
        <DescriptionText>
          Główny punkt programu. Czas, w którym wszyscy zbieramy się w jednym miejscu aby uwielbiać Boga
          i słuchać inspirujących kazań.
        </DescriptionText>
      </DescriptionBox>
      <DescriptionBox>
        <DescriptionTitle>
          Warsztaty
        </DescriptionTitle>
        <DescriptionText>
          Dla tych, którzy chcą zgłębić swoją wiedzę w konkretnym temacie mamy przygotowany szereg ciekawych tematów, które poprowadzą specjaliści
          z wieloletnim stażem.
        </DescriptionText>
      </DescriptionBox>
      <DescriptionBox>
        <DescriptionTitle>
          Sesje specjalne
        </DescriptionTitle>
        <DescriptionText>
          Specjalny czas na poruszenie wyjątkowych tematów, prowadzony przez gości, którzy będą dzielić się swoim doświadczeniem i wiedzą.
        </DescriptionText>
      </DescriptionBox>
    </DescriptionsContainer>
  </Page>
);

export default Plan;
