import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Glide, { Swipe, Controls } from '@glidejs/glide/dist/glide.modular.esm';

import { PageTitle } from '../index';
import SpeakersSlider from './speakersSlider';
import SpeakersMobile from './speakersMobile';

const Page = styled.div`
  position: relative;
  color: #F5F3F0;
`;

const PageBackground = styled.div`
  position: absolute;
  background-color: #F79065;
  width: 100%;
  height: 100%;
  z-index: -1;
  @media (min-width: 960px) {
    width: calc(100% - 40px);
    margin: 0 20px;
  }
`;

const SpeakersIlu = styled(Img)`
  position: absolute !important;
  pointer-events: none;
  -webkit-touch-callout: none; 
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const SpeakersIlu1 = styled(SpeakersIlu)`
  width: 80vw;
  max-width: 900px;
  bottom: -20vh;
  right: -45vw;
  z-index: 1;
  @media (min-width: 768px) {
    width: 40vw;
    bottom: -20vh;
    right: -5vw;
  }
`;

const SpeakersIlu2 = styled(SpeakersIlu)`
  width: 80vw;
  max-width: 900px;
  top: -8vh;
  right: 10vw;
  z-index: 1;
  @media (min-width: 768px) {
    width: 50vw;
  }
`;

const SpeakersIlu3 = styled(SpeakersIlu)`
  width: 80vw;
  max-width: 900px;
  bottom: -2vh;
  left: -8vw;
  z-index: 1;
  display: none;
  @media (min-width: 768px) {
    width: 40vw;
    bottom: -2vh;
    left: -8vw;
    display: block;
  }
`;

const SpeakersIlu4 = styled(SpeakersIlu)`
  width: 80vw;
  max-width: 900px;
  bottom: -4vh;
  left: -2vw;
  z-index: -1;
  display: none;
  @media (min-width: 768px) {
    width: 50vw;
    display: block;
  }
`;


const Speakers = ({ data }) => (
  <Page id="mowcy" className="page page--dark">
    <PageBackground />
    <PageTitle>
          Mówcy
    </PageTitle>
    {/* <SpeakersList> */}
    {/*  LEO BIGGER | RENE WAGNER<br/> */}
    {/*  WIKTORIA MACIEJEWICZ | KAROL MICHALAK<br/> */}
    {/*  ARKADIUSZ KUCZYŃSKI | MICHAŁ WŁODARCZYK */}
    {/* </SpeakersList> */}
    <SpeakersSlider data={data} />
    <SpeakersMobile data={data} />
    <SpeakersIlu1 fluid={data.cloud1.childImageSharp.fluid} />
    <SpeakersIlu2 fluid={data.cloud2.childImageSharp.fluid} />
    {/*<SpeakersIlu3 fluid={data.cloud3.childImageSharp.fluid} />*/}
    <SpeakersIlu4 fluid={data.cloud5.childImageSharp.fluid} />
  </Page>
);

export default Speakers;
