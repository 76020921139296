import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { PageTitle } from './index';

import { MenuContext } from '../menu_context';

const Page = styled.div`
    
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    /* display: none; */
    @media (min-width: 768px) {
        align-items: flex-start;
    }
`;

const MenuBackground = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #F5F3F0;
    padding-top: 60px;
    @media (min-width: 768px) {
        width: 585px;
        max-height: 875px;
        left: 30px;
    }
`;

const Title = styled(PageTitle)`
    font-size: 60px !important;
`;

const MenuContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 10px 35px 50px;
`;

const MenuLinkContainer = styled((props) => <Link {...props} />)`
  display: flex;
  justify-content: space-between;
  font-family: 'Montserrat Bold',serif;
  text-transform: uppercase;
  font-size: 16px;
  padding: 8px 0;
  color: rgba(128, 128, 128, .5);
  text-decoration: none;
  &:hover {
        color: #808080;
  }
`;

const MenuLink = styled.div`
  text-decoration: none;
  //color: inherit;
`;

const MenuLinkNumber = styled.div`
  
`;

const CloseContainer = styled.div`
  width: 25px;
  height: 25px;
  position: absolute;
  top: 25px;
  right: 33px;
  padding: 5px;
  
  @media (min-width: 768px) {
    top: 43px;
    left: 22px;
  }
`;

const Close = styled.div`
  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 44px;
    width: 44px;
    content: "";
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
  }
  position: relative;
  cursor: pointer;
`;

const CloseImg = styled(Img)`
    width: 100%;
    height: 100%;
    cursor: pointer;
`;


const Menu = ({ data, setMenu }) => {
  const useOutsideAlerter = (ref) => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setMenu(false);
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    });
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <MenuContext.Consumer>
      {({ menuState, toggleMenu }) => (
        <Page style={{ display: menuState ? 'flex' : 'none' }}>
          <MenuBackground ref={wrapperRef}>
            <CloseContainer>
              <Close onClick={toggleMenu}>
                <CloseImg fluid={data.x.childImageSharp.fluid} />
              </Close>
            </CloseContainer>
            <Title>
              Menu
            </Title>
            <MenuContainer>
              <MenuLinkContainer onClick={toggleMenu} to="/#">
                <MenuLink>Home</MenuLink>
                <MenuLinkNumber>
                  1
                </MenuLinkNumber>
              </MenuLinkContainer>
              <MenuLinkContainer onClick={toggleMenu} to="/#tworca">
                <MenuLink>Baza Centrum X Twórca</MenuLink>
                <MenuLinkNumber>
                  2
                </MenuLinkNumber>
              </MenuLinkContainer>
              <MenuLinkContainer onClick={toggleMenu} to="/#mowcy">
                <MenuLink>Mówcy</MenuLink>
                <MenuLinkNumber>
                  3
                </MenuLinkNumber>
              </MenuLinkContainer>
              <MenuLinkContainer onClick={toggleMenu} to="/#mowcy">
                <MenuLink>Sesje specjalne</MenuLink>
                <MenuLinkNumber>
                  4
                </MenuLinkNumber>
              </MenuLinkContainer>
              <MenuLinkContainer onClick={toggleMenu} to="/#warsztaty">
                <MenuLink>Warsztaty</MenuLink>
                <MenuLinkNumber>
                  5
                </MenuLinkNumber>
              </MenuLinkContainer>
              <MenuLinkContainer onClick={toggleMenu} to="/#rejestracja">
                <MenuLink>Kup bilet</MenuLink>
                <MenuLinkNumber>
                  6
                </MenuLinkNumber>
              </MenuLinkContainer>
              <MenuLinkContainer onClick={toggleMenu} to="/#wolontariat">
                <MenuLink>Wolontariat</MenuLink>
                <MenuLinkNumber>
                  7
                </MenuLinkNumber>
              </MenuLinkContainer>
              <MenuLinkContainer onClick={toggleMenu} to="/#plan">
                <MenuLink>Plan</MenuLink>
                <MenuLinkNumber>
                  8
                </MenuLinkNumber>
              </MenuLinkContainer>
              <MenuLinkContainer onClick={toggleMenu} to="/#strefy-atrakcje">
                <MenuLink>Strefy X Atrakcje</MenuLink>
                <MenuLinkNumber>
                  9
                </MenuLinkNumber>
              </MenuLinkContainer>
              <MenuLinkContainer onClick={toggleMenu} to="/#wydarzenia">
                <MenuLink>Wydarzenia</MenuLink>
                <MenuLinkNumber>
                  10
                </MenuLinkNumber>
              </MenuLinkContainer>
              <MenuLinkContainer onClick={toggleMenu} to="/#media">
                <MenuLink>Media</MenuLink>
                <MenuLinkNumber>
                  11
                </MenuLinkNumber>
              </MenuLinkContainer>
              <MenuLinkContainer onClick={toggleMenu} to="/#kontakt">
                <MenuLink>Kontakt</MenuLink>
                <MenuLinkNumber>
                  12
                </MenuLinkNumber>
              </MenuLinkContainer>
            </MenuContainer>
          </MenuBackground>
        </Page>
      )}

    </MenuContext.Consumer>
  );
};

export default Menu;
