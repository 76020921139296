import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Glide, { Swipe, Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm';

import { PageTitle } from './index';

const Page = styled.div`
  position: relative;
  //color: #F5F3F0;
  padding-bottom: 80px;
`;

const AtractionsPageTitle = styled(PageTitle)`
  color: #808080;
    @media (min-width: 768px) {
    -webkit-text-fill-color: rgba(0,0,0,0);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #707070;
  }
`;

const PageBackground = styled.div`
  position: absolute;
  //background-color: #F79065;
  width: 100%;
  height: 100%;
  z-index: -1;
  @media (min-width: 768px) {
    width: calc(100% - 40px);
    margin: 0 20px;
  }
`;

const GlideUl = styled.ul`
  margin: 0;
`;

const GlideSlider = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
    padding-bottom: 80px;
  }
`;

const MobileSpeakers = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

const Slide = styled.li`
  position: relative;
  list-style: none;
  padding-bottom: 20px;
  margin-bottom: 0;
  color: #F5F3F0;
  @media (min-width: 768px) {
    padding-bottom: 0;
    cursor: grab;
  }
`;

const SlideDesc = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  justify-items: flex-start;
  flex-direction: column;
  padding: 10px;
  @media (min-width: 768px){
    padding: 35px 50px;
  }
  //backdrop-filter: blur(2px);
`;

const SlideDescTitle = styled.div`
  font-family: 'Morganite Black', serif;
  font-size: 40px;
  
  line-height: 1;
  text-transform: uppercase;
  @media (min-width: 768px){
    font-size: 60px;
    padding-bottom: 10px;
  }
`;

const SlideDescText = styled.div`
  font-family: "Montserrat Medium", serif;
  font-size: 12px;
  line-height: 1.3;
  
  @media (min-width: 768px) and (max-width: 1060px) {
    max-width: 100%;
  }
  @media (min-width: 768px){
    font-size: 14px;
    max-width: 80%;
  }
`;

const SlideMobileImgContainer = styled.div`
  width: auto;
  //height: 30vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
`;

const SliderMobileImg = styled(Img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const SlideMobileDesc = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100vw - 50px);
  height: 100%;
  z-index: 2;
  padding: 0 35px;
`;

const SlideMobileDescTitle = styled.div`
  position: absolute;
  bottom: 50px;
  left: 50px;
  font-family: 'Morganite Black', serif;
  font-size: 55px;
  text-transform: uppercase;
`;

const SlideMobileDescText = styled.div`
  position: absolute;
  left: 50px;
  bottom: 40px;
  font-family: "Montserrat Medium", serif;
  font-size: 12px;
  line-height: 1.5;
`;

const SliderNav = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;


const GlideBulletsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 0;
`;

const GlideBullet = styled.button`
  border-radius: 50%;
  width: 10px;
  height: 10px;
  padding: 0;
  margin: 0 5px;
  border: none;
  opacity: .33;
  outline: 0;
  cursor: pointer;
  background-color: #808080;
`;

const SlideArrowLeft = styled.div`
  position: relative;
  width: 10px;
  height: 16px;
  margin: 0 30px;
  cursor: pointer;
  filter: brightness(.5);
  &:before {
  content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 25px;
  }
`;

const SlideArrowRight = styled(SlideArrowLeft)`
  transform: rotate(180deg);
`;

const SpeakersList = styled.div`
  display: none;
  position: absolute;
  top: 117px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  max-width: 1400px;
  font-family: "Montserrat Medium", serif;
  font-size: 14px;
  letter-spacing: 1px;
  text-align: right;
  padding-right: 35px;
  @media (min-width: 768px) {
    display: block;
  }
`;

const AttractionsContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`;

const TopAttractions = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  height: auto;
  margin-top: 100px;
  
  @media (min-width: 960px) {
    display: grid;
    grid: 1fr / 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`;

const BottomAttractions = styled(TopAttractions)`
  margin-top: 15px;
  margin-bottom: 180px;
`;

const AttractionItemOne = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-start: 3;
  grid-column-end: 8;
  position: relative;
  color: #F5F3F0;
  margin: 10px 20px;
  @media (min-width: 960px) {
    margin: 0;
  }
`;

const AttractionItemTwo = styled(AttractionItemOne)`
  @media (min-width: 960px) {
    grid-column-start: 8;
    grid-column-end: 13;
  }
`;

const AttractionItemThree = styled(AttractionItemOne)`
  @media (min-width: 960px) {
    grid-column-start: 1;
    grid-column-end: 6;
  }
`;

const AttractionItemFour = styled(AttractionItemOne)`
  @media (min-width: 960px) {
    grid-column-start: 6;
    grid-column-end: 11;
  }
`;

const AttractionItemImage = styled(Img)`
  width: 100%;
  @media (min-width: 960px) {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const AttractionItemTitle = styled.div`
  position: absolute;
`;

const AttractionItemText = styled.div`
  position: absolute;
`;

let speakersSlider;

const Speaker = ({ name, desc, img }) => (
  <Slide className="glide__slide">
    <Img fluid={img} alt={name} />
    <SlideDesc>
      <SlideDescTitle className="title">
        {name}
      </SlideDescTitle>
    </SlideDesc>
  </Slide>
);

const SpeakerMobile = ({ name, desc, img }) => (
  <Slide className="glide__slide">
    <SlideMobileImgContainer>
      <SliderMobileImg fluid={img} alt={name} />
    </SlideMobileImgContainer>
    <SlideMobileDesc>
      <SlideMobileDescTitle className="title">
        {name}
      </SlideMobileDescTitle>
    </SlideMobileDesc>
  </Slide>
);

const Attractions = ({ data }) => {
  useEffect(() => {
    speakersSlider = new Glide('.glide__atractions', {
      type: 'slider',
      perView: 2,
      focusAt: 0,
      peek: 350,
      startAt: 0,
      breakpoints: {
        1400: {
          perView: 1,
          peek: 350,
        },
        1050: {
          perView: 1,
          peek: 150,
        },
      },
    });

    speakersSlider.mount({ Swipe, Controls, Breakpoints });

    document.querySelector('.slide-attractions-prev').addEventListener('click', () => speakersSlider.go('<'));
    document.querySelector('.slide-attractions-next').addEventListener('click', () => speakersSlider.go('>'));
  }, []);

  return (
    <Page id="strefy-atrakcje" className="page">
      <PageBackground />
      <AtractionsPageTitle>
        Strefy/Atrakcje
      </AtractionsPageTitle>
      <AttractionsContainer>
        <TopAttractions>
          <AttractionItemOne>
            <AttractionItemImage fluid={data.atr_1.childImageSharp.fluid} alt="" />
            <SlideDesc>
              <SlideDescTitle>
                Strefa Relaksu
              </SlideDescTitle>
              <SlideDescText>
                Odpocznij i spędź czas z przyjaciółmi w specjalnie przygotowanej przestrzeni z relaksującą muzyką. Ponadto weź udział w świetnych inicjatywach artystycznych i doładuj się pyszną kawą przygotowywaną przez naszych baristów.
              </SlideDescText>
            </SlideDesc>
          </AttractionItemOne>
          <AttractionItemTwo>
            <AttractionItemImage fluid={data.atr_4.childImageSharp.fluid} alt="" />
            <SlideDesc>
              <SlideDescTitle>
                Strefa Interakcji
              </SlideDescTitle>
              <SlideDescText>
                Strefa dla geeków, nerdów i e-sportowców. Weź udział w jednym z przygotowanych dla ciebie turniejów, zgarnij nagrodę i spraw, żeby świat o tobie usłyszał. A jeśli zwyczajnie szukasz dobrej zabawy, skorzystaj z cymbergaja, gier na konsole i JustDance.
              </SlideDescText>
            </SlideDesc>
          </AttractionItemTwo>
        </TopAttractions>
        <BottomAttractions>
          <AttractionItemThree>
            <AttractionItemImage fluid={data.atr_2.childImageSharp.fluid} alt="" />
            <SlideDesc>
              <SlideDescTitle>
                Skateart
              </SlideDescTitle>
              <SlideDescText>
                W dwóch słowach Skate i Art. Weź deseczkę, zagraj w kosza i wyżyj się na ściance graffiti. Wszystko to w ulicznym klimacie, którym będziesz chciał podzielić się na swoim instagramie.
              </SlideDescText>
            </SlideDesc>
          </AttractionItemThree>
          <AttractionItemFour>
            <AttractionItemImage fluid={data.atr_3.childImageSharp.fluid} alt="" />
            <SlideDesc>
              <SlideDescTitle>
                Klub Prąd
              </SlideDescTitle>
              <SlideDescText>
                Codziennie inny DJ i najlepsza imprezowa muzyka! Pyszne, kolorowe koktajle, integracja i zabawy podczas których na pewno poznasz masę nowych ludzi! To wciąż za mało żeby oddać legendarny klimat Klubu Prąd, którego nie zmyjesz z siebie jeszcze długo po Bazie.
              </SlideDescText>
            </SlideDesc>
          </AttractionItemFour>
        </BottomAttractions>
      </AttractionsContainer>
      <GlideSlider className="glide__atractions">
        <div data-glide-el="track" className="glide__track">
          <GlideUl className="glide__slides">
            <Speaker
              name="Silent disco"
              img={data.atrakcja_01.childImageSharp.fluid}
            />
            <Speaker
              name="Brooklyn jam"
              img={data.atrakcja_02.childImageSharp.fluid}
            />
            <Speaker
              name="Cymbergaj Duel Night"
              img={data.atrakcja_03.childImageSharp.fluid}
            />
            <Speaker
              name="Jam session"
              img={data.atrakcja_04.childImageSharp.fluid}
            />
            <Speaker
              name="BC basketball"
              img={data.atrakcja_05.childImageSharp.fluid}
            />
            <Speaker
              name="Wielka integracja"
              img={data.atrakcja_06.childImageSharp.fluid}
            />
            <Speaker
              name="Just Dance"
              img={data.atrakcja_07.childImageSharp.fluid}
            />
            <Speaker
              name="Wieczór filmowy"
              img={data.atrakcja_08.childImageSharp.fluid}
            />
            <Speaker
              name="Wieczorek kabaretowy"
              img={data.atrakcja_09.childImageSharp.fluid}
            />
            <Speaker
              name="Bc liga"
              img={data.atrakcja_11.childImageSharp.fluid}
            />
            <Speaker
              name="Wieczór uwielbienia"
              img={data.atrakcja_12.childImageSharp.fluid}
            />
          </GlideUl>
        </div>
        <SliderNav>
          <SlideArrowLeft className="slide-attractions-prev">
            <Img fluid={data.slide_arrow.childImageSharp.fluid} />
          </SlideArrowLeft>
          <GlideBulletsContainer className="glide__bullets" data-glide-el="controls[nav]">
            <GlideBullet className="glide__bullet" data-glide-dir="=0" />
            <GlideBullet className="glide__bullet" data-glide-dir="=1" />
            <GlideBullet className="glide__bullet" data-glide-dir="=2" />
            <GlideBullet className="glide__bullet" data-glide-dir="=3" />
            <GlideBullet className="glide__bullet" data-glide-dir="=4" />
            <GlideBullet className="glide__bullet" data-glide-dir="=5" />
            <GlideBullet className="glide__bullet" data-glide-dir="=6" />
            <GlideBullet className="glide__bullet" data-glide-dir="=7" />
            <GlideBullet className="glide__bullet" data-glide-dir="=8" />
            <GlideBullet className="glide__bullet" data-glide-dir="=9" />
            <GlideBullet className="glide__bullet" data-glide-dir="=10" />
            <GlideBullet className="glide__bullet" data-glide-dir="=11" />
          </GlideBulletsContainer>
          <SlideArrowRight className="slide-attractions-next">
            <Img fluid={data.slide_arrow.childImageSharp.fluid} />
          </SlideArrowRight>
        </SliderNav>
      </GlideSlider>
      <MobileSpeakers>
        <SpeakerMobile
          name="Silent disco"
          img={data.atrakcja_01.childImageSharp.fluid}
        />
        <SpeakerMobile
          name="Brooklyn jam"
          img={data.atrakcja_02.childImageSharp.fluid}
        />
        <SpeakerMobile
          name="Cymbergaj Duel Night"
          img={data.atrakcja_03.childImageSharp.fluid}
        />
        <SpeakerMobile
          name="Jam session"
          img={data.atrakcja_04.childImageSharp.fluid}
        />
        <SpeakerMobile
          name="BC basketball"
          img={data.atrakcja_05.childImageSharp.fluid}
        />
        <SpeakerMobile
          name="Wielka integracja"
          img={data.atrakcja_06.childImageSharp.fluid}
        />
        <SpeakerMobile
          name="Just Dance"
          img={data.atrakcja_07.childImageSharp.fluid}
        />
        <SpeakerMobile
          name="Wieczór filmowy"
          img={data.atrakcja_08.childImageSharp.fluid}
        />
        <SpeakerMobile
          name="Wieczorek kabaretowy"
          img={data.atrakcja_09.childImageSharp.fluid}
        />
        <SpeakerMobile
          name="Wielki finał"
          img={data.atrakcja_10.childImageSharp.fluid}
        />
        <SpeakerMobile
          name="Bc liga"
          img={data.atrakcja_11.childImageSharp.fluid}
        />
        <SpeakerMobile
          name="Wieczór uwielbienia"
          img={data.atrakcja_12.childImageSharp.fluid}
        />
      </MobileSpeakers>
    </Page>
  );
};

export default Attractions;
