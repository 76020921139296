import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { PageTitle } from './index';

const Page = styled.div`
  background-color: #f5f3f0;
  padding: 200px 0;
  //display: none;
  position: relative;
  @media (min-width: 768px) {
    display: block;
  }
`;

const RegImage = styled(Img)`
  margin: 0 -100px;
  z-index: 9;
`;

const RejeIlu = styled(Img)`
  position: absolute !important;
  pointer-events: none;
  -webkit-touch-callout: none; 
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const RejeIlu1 = styled(RejeIlu)`
  width: 80vw;
  max-width: 900px;
  bottom: 24vh;
  left: -22vw;
  z-index: 1;
  @media (min-width: 768px) {
    width: 40vw;
    bottom: 4vh;
    left: -12vw;
  }
`;

const RejeIlu2 = styled(RejeIlu)`
  width: 80vw;
  max-width: 900px;
  bottom: 4vh;
  right: -32vw;
  z-index: 1;
  @media (min-width: 768px) {
    width: 40vw;
    bottom: 10vh;
    right: -10vw;
  }
`;

const RegistrationPlaceholder = ({ data }) => (
  <Page id="rejestracja" className="page">
    <RegImage fluid={data.regPlaceholder.childImageSharp.fluid} />
    <RejeIlu1 fluid={data.leaf_rej_1.childImageSharp.fluid} />
    <RejeIlu2 fluid={data.leaf_rej_2.childImageSharp.fluid} />
  </Page>
);

export default RegistrationPlaceholder;
