import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { PageTitle } from './index';

import { RegistrationBox, SmallRegistrationBox } from '../registrationBox';
import RegistrationForm from './registrationForm';
import { RegistrationBoxWolo } from '../registrationBoxWolo'
import RegistrationFormWolo from './registrationFormWolo'
import Glide, { Controls, Swipe } from "@glidejs/glide/dist/glide.modular.esm"

const Page = styled.div`
    background-color: #F5F3F0;
    color: #707070;
    padding-bottom: 200px;
`;

const RegisterPageTitle = styled(PageTitle)`
  color: #707070;
    @media (min-width: 768px) {
    -webkit-text-fill-color: rgba(0,0,0,0);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #707070;
  }
`;

const WoloTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
`;

const WoloContainer = styled.div`
  display: flex;
  max-width: 1230px;
  padding: 100px 35px 0;
  margin: 0 auto;
  justify-content: space-between;
  flex-direction: column;
  
  @media (min-width: 960px) {
    flex-direction: row;
  }
`;

const RegistrationContainer = styled.div`
  width: 100%;
  //display: flex;
  //justify-content: center;
  //align-items: center;
  //position: absolute;
  //overflow: hidden;
  @media (min-width: 768px) {
    display: flex;
    grid-gap: 20px;
    margin: 0 auto;
    padding: 0 50px 100px;
  }
  @media (min-width: 1070px) {
    display: flex;
    grid-gap: 20px;
    padding: 0 50px 100px;
  }
`;

const WoloTextTitle = styled.div`
  font-size: 18px;
  font-family: "Montserrat Bold", sans-serif;
  margin-bottom: 30px;
`;

const WoloTextContent = styled.div`
  display: flex;
  font-family: "Montserrat Medium", sans-serif;
  font-size: 16px;
`;

const GlideUl = styled.ul`
  margin: 0;
`;

const GlideSlider = styled.div`
  //display: none;
  //@media (min-width: 960px) {
    display: block;
    padding-bottom: 80px;
    padding-top: 80px;
  //}
`;

const Slide = styled.li`
  position: relative;
  list-style: none;
  padding-bottom: 20px;
  margin-bottom: 0;
  @media (min-width: 960px) {
    padding-bottom: 0;
    cursor: grab;
  }
`;

const SlideDesc = styled.div`
  width: 100%;
  height: 100%;
  //position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  justify-items: flex-start;
  flex-direction: column;
  //padding: 35px 50px;
`;

const SlideDescTitle = styled.div`
  font-size: 18px;
  font-family: "Montserrat Bold", sans-serif;
  margin-bottom: 30px;
`;

const SlideDescText = styled.div`
  display: flex;
  font-family: "Montserrat Medium", sans-serif;
  font-size: 16px;
`;

const WoloSlider = styled.div`

`;

const RegistrationBoxContainer = styled.div`
  
`;

const SliderNav = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;


const GlideBulletsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 0;
`;

const GlideBullet = styled.button`
  border-radius: 50%;
  width: 10px;
  height: 10px;
  padding: 0;
  margin: 0 5px;
  border: none;
  opacity: .33;
  outline: 0;
  cursor: pointer;
  background-color: #808080;
`;

const SlideArrowLeft = styled.div`
  position: relative;
  width: 10px;
  height: 16px;
  margin: 0 30px;
  cursor: pointer;
  filter: brightness(.5);
  &:before {
  content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 25px;
  }
`;

const SlideArrowRight = styled(SlideArrowLeft)`
  transform: rotate(180deg);
`;

const Speaker = ({ name, desc }) => (
  <Slide className="glide__slide">
    <SlideDesc>
      <SlideDescTitle className="title">
        {name}
      </SlideDescTitle>
      <SlideDescText className="text">
        {desc}
      </SlideDescText>
    </SlideDesc>
  </Slide>
);

let woloSlider;

const RegistrationWolo = ({ data }) => {
  const [price, setPrice] = useState({
    wolo: 50,
  });
  if (typeof window !== 'undefined') {
    useEffect(() => {
      woloSlider = new Glide('.glide__wolo', {
        type: 'slider',
        perView: 1,
        focusAt: 'center',
        startAt: 0,
      });

      woloSlider.mount({ Swipe, Controls });

      document.querySelector('.slide-wolo-prev').addEventListener('click', () => woloSlider.go('<'));
      document.querySelector('.slide-wolo-next').addEventListener('click', () => woloSlider.go('>'));
    }, []);
  }

  const [formData, setFormData] = useState({
    first_name: false,
    last_name: false,
    sex: false,
    birthdate: false,
    city: false,
    email: false,
    phone: false,
    church: false,
    accommodation: false,
    deluxe: false,
    breakfast: true,
    dinner: true,
    reference_name: false,
    team: false,
    skills: false,
    data_processing_permission: false,
    media_processing_permission: false,
    terms_accepted: false,
  });

  const [registerForm, setRegisterForm] = useState(false);

  return (
    <Page id="wolontariat" className="page">
      <RegisterPageTitle>
        Wolontariat
      </RegisterPageTitle>
      <WoloContainer>
        <WoloTextContainer>
          <WoloTextTitle>
            Wyślij zgłoszenie i dołącz do zespołu wolontariuszy.
            Twórz Bazę Centrum razem z nami!
          </WoloTextTitle>
          <WoloTextContent>
            Pamiętaj, że jako wolontariusz musisz mieć skończone 16 lat. Przygotuj się również na przyjazd dzień przed konferencją
            i na późny wyjazd po zakończeniu.
          </WoloTextContent>
          <GlideSlider className="glide__wolo">
            <div data-glide-el="track" className="glide__track">
              <GlideUl className="glide__slides">
                <Speaker
                  name="BAZA CAFE"
                  desc="Miejsce, gdzie możesz smacznie zjeść, odpocząć i spędzić chwilę na rozmowie z przyjaciółmi, bądź nawiązać nowe znajomości. Jako wolontariusze serwujemy jedzenie na  najwyższym poziomie oraz dbamy o atmosferę tak, by dogodzić głodnym i spragnionym Bazowiczom."
                />
                <Speaker
                  name="OBSŁUGA"
                  desc="Będziesz pomocną dłonią dla organizatorów i wsparciem dla każdego zespołu. Jeśli jesteś niezdecydowany jakiej służby się podjąć, to miejsce jest dla Ciebie, ponieważ będziesz mógł sprawdzić się w różnych zadaniach jednocześnie, a przy tym poznać wiele osób."
                />
                <Speaker
                  name="OCHRONA I STREFA NOCLEGOWA"
                  desc="Ten zespół jest odpowiedzialny za bezpieczeństwo uczestników i pracowników na Bazie Centrum. A także  przygotowanie miejsca do spania, pilnowanie odpowiedniego sposobu użytku miejsca noclegowego oraz przestrzegania ciszy nocnej. Jeśli chcesz podjąć się tego zadania, powinieneś robić to z pokornym sercem pełnym miłości i cierpliwości. Wtedy służba przyniesie ci radość i będziesz wielką zachętą dla innych."
                />
                <Speaker
                  name="REJESTRACJA"
                  desc="Ty i Twój team, będziecie niezwykle potrzebni młodzieży, wolontariuszom i gościom, by pomóc im w sprawach praktycznych oraz by odpowiadać na ich pytania."
                />
                <Speaker
                  name="SŁUŻBA PORZĄDKOWA"
                  desc="Jeżeli kochasz Boga i ludzi oraz nie boisz się im służyć, jeżeli uwielbiasz zaglądać do zakazanych miejsc, jeżeli nie przeszkadzają Ci żadne zapachy, jeżeli lubisz zmieniać rzeczywistość wokół siebie, to musisz dołączyć do BC clean team!"
                />
                <Speaker
                  name="WARSZTATY"
                  desc="Twoje zadanie to obsługa uczestników w punkcie informacyjnym, zapisywanie na warsztaty i udzielanie informacji na ich temat. Jest to także przygotowanie materiałów i sal a po ich zakończeniu przywrócenie pomieszczenia do stanu pierwotnego."
                />
                <Speaker
                  name="PIERWSZY KONTAKT I WSPARCIE HALI GŁÓWNEJ"
                  desc="Twoim zadaniem będzie entuzjastyczne witanie i udzielanie wszelkich informacji uczestnikom konferencji. Będziesz miał realny wpływ na to, żeby poczuli się zaopiekowani i zauważeni, a także będziesz odpowiadał za wygląd hali głównej przed i po spotkaniach w hali głównej."
                />
              </GlideUl>
            </div>
            <SliderNav>
              <SlideArrowLeft className="slide-wolo-prev">
                <Img fluid={data.slide_arrow.childImageSharp.fluid} />
              </SlideArrowLeft>
              <GlideBulletsContainer className="glide__bullets" data-glide-el="controls[nav]">
                <GlideBullet className="glide__bullet" data-glide-dir="=0" />
                <GlideBullet className="glide__bullet" data-glide-dir="=1" />
                <GlideBullet className="glide__bullet" data-glide-dir="=2" />
                <GlideBullet className="glide__bullet" data-glide-dir="=3" />
                <GlideBullet className="glide__bullet" data-glide-dir="=4" />
                <GlideBullet className="glide__bullet" data-glide-dir="=5" />
                <GlideBullet className="glide__bullet" data-glide-dir="=6" />
              </GlideBulletsContainer>
              <SlideArrowRight className="slide-wolo-next">
                <Img fluid={data.slide_arrow.childImageSharp.fluid} />
              </SlideArrowRight>
            </SliderNav>
          </GlideSlider>
        </WoloTextContainer>
          <RegistrationBoxWolo
            name="Pakiet wolo"
            type="fn"
            price={50}
            desc="Zapewniony nocleg na hali sportowej 500m od miejsca konferencji."
            bottomText=""
            fluid={data.wolo_reg.childImageSharp.fluid}
            checkbox={data.xCheckBox.childImageSharp.fluid}
            regElement="wolo"
            mainPrice={price}
            setMainPrice={setPrice}
            formData={formData}
            setFormData={setFormData}
            changeFormState={setRegisterForm}
          />
        <RegistrationFormWolo regArr={data.regArr.childImageSharp.fluid} regImg={data.registrationImg.childImageSharp.fluid} closeRegister={data.closeRegister.childImageSharp.fluid} visible={registerForm} setRegisterForm={setRegisterForm} crossImage={data.xCheckBox.childImageSharp.fluid} formData={formData} setFormData={setFormData} />
      </WoloContainer>
    </Page>
  );
};

export default RegistrationWolo;
