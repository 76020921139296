/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { createGlobalStyle } from 'styled-components';
import OGImage from '../images/TworcaCoverWeb.png';

import Header from './header';
import Footer from './footer';
import './layout.css';
import '../../node_modules/@glidejs/glide/dist/css/glide.core.min.css';

if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  const SmoothScroll = require('smooth-scroll');
  const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 800,
    speedAsDuration: true,
  });
}

const Layout = ({
  data, toggleMenu, children, scroll,
}) => {
  const GlobalStyle = createGlobalStyle``;

  return (
    <>
      <Helmet>
        <title>Baza Centrum 2019</title>

        <meta property="og:image" content={OGImage} />
        <meta property="og:locale" content="pl_PL" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Baza Centrum 2019" />
        <meta property="og:description" content="3 dni, wiele historii i setki młodych osób z całego kraju pragnących doświadczać Boga. Baza Centrum to wydarzenie, które od wielu lat zmienia ludzi, przekazując niezmienną treść Ewangelii." />
        {/*<meta property="og:url" content="https://bazacentrum.pl" />*/}
        <meta property="og:updated_time" content="2019-09-01" />
      </Helmet>
      <GlobalStyle />
      <Header toggleMenu={toggleMenu} scroll={scroll} />
      <div
        style={{
          margin: '0 auto',
          paddingTop: 0,
        }}
      >
        <main>{children}</main>
      </div>
      <Footer data={data} scroll={scroll} />
    </>
  );
};
export default Layout;
