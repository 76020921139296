import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Glide, { Swipe, Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm';

import { PageTitle } from './index';

const Page = styled.div`
  position: relative;
  color: #F5F3F0;
  padding-bottom: 50px;
  //display: none;
  //@media (min-width: 768px) {
  //  display: block;
  //}
`;

const PageBackground = styled.div`
  position: absolute;
  background-color: #F79065;
  width: 100%;
  height: 100%;
  z-index: -1;
  @media (min-width: 768px) {
    width: calc(100% - 40px);
    margin: 0 20px;
  }
`;

const GlideUl = styled.ul`
  margin: 0;
`;

const GlideSlider = styled.div`
  display: none;
  @media (min-width: 960px) {
    display: block;
    padding-bottom: 80px;
  }
`;

const MobileSpeakers = styled.div`
  @media (min-width: 960px) {
    display: none;
  }
`;

const Slide = styled.li`
  position: relative;
  list-style: none;
  padding-bottom: 20px;
  margin-bottom: 0;
  @media (min-width: 960px) {
    padding-bottom: 0;
    cursor: grab;
  }
`;

const SlideContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  //backdrop-filter: blur(3px);
  //background-color: rgba(0,0,0,.3);
`;

const SlideDesc = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  justify-items: flex-start;
  flex-direction: column;
  padding: 35px 50px;
`;

const SubTitle = styled.div`
  font-family: 'Morganite Black', serif;
  font-size: 28px;
  padding-bottom: 10px;
  line-height: 1;
  text-transform: uppercase;
  margin: 10px 0;
  
  @media (min-width: 960px) {
    font-size: 40px;
    margin: 0;
  }
`;

const SlideDescTitle = styled.div`
  font-family: 'Morganite Black', serif;
  font-size: 70px;
  padding-bottom: 10px;
  line-height: 1;
  text-transform: uppercase;
`;

const SlideDescText = styled.div`
  font-family: "Montserrat Medium", serif;
  font-size: 14px;
  line-height: 1.3;
  max-width: 80%;
  text-transform: uppercase;
  @media (min-width: 768px) and (max-width: 1060px) {
    max-width: 100%;
  }
`;

const SlideMobileImgContainer = styled.div`
  width: auto;
  //height: 30vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
`;

const SliderMobileImg = styled(Img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const SlideMobileDesc = styled.div`
  position: absolute;
  z-index: 2;
  padding: 15px 35px;
  bottom: 20px;
`;

const SlideMobileDescTitle = styled.div`
  position: relative;
  font-family: 'Morganite Black', serif;
  font-size: 50px;
  text-transform: uppercase;
  margin-bottom: 10px;

`;

const SlideMobileDescText = styled.div`
  font-family: "Montserrat Medium", serif;
  font-size: 14px;
  line-height: 1.5;
  position: relative;
`;

const SliderNav = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;


const GlideBulletsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 0;
`;

const GlideBullet = styled.button`
  border-radius: 50%;
  width: 10px;
  height: 10px;
  padding: 0;
  margin: 0 5px;
  border: none;
  opacity: .33;
  outline: 0;
  cursor: pointer;
`;

const SlideArrowLeft = styled.div`
  position: relative;
  width: 10px;
  height: 16px;
  margin: 0 30px;
  cursor: pointer;
  &:before {
  content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 25px;
  }
`;

const SlideArrowRight = styled(SlideArrowLeft)`
  transform: rotate(180deg);
`;

const SpeakersList = styled.div`
  display: none;
  position: absolute;
  top: 117px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  max-width: 1400px;
  font-family: "Montserrat Medium", serif;
  font-size: 14px;
  letter-spacing: 1px;
  text-align: right;
  padding-right: 35px;
  @media (min-width: 768px) {
    display: block;
  }
`;

let speakersSlider;
let speakersSlider2;

const Workshop = ({
  name, desc, author, img,
}) => (
  <Slide className="glide__slide">
    <Img fluid={img} alt={name} />
    <SlideContainer>
      <SlideDesc>
        <SubTitle>
          {author}
        </SubTitle>
        <SlideDescTitle className="title">
          {name}
        </SlideDescTitle>
        <SlideDescText className="text">
          {desc}
        </SlideDescText>
      </SlideDesc>
    </SlideContainer>
  </Slide>
);

const WorkshopMobile = ({
  name, desc, author, img,
}) => (
  <Slide className="glide__slide">
    <SlideMobileImgContainer>
      <SliderMobileImg fluid={img} alt={name} />
    </SlideMobileImgContainer>
    <SlideMobileDesc>
      <SubTitle>
        {author}
      </SubTitle>
      <SlideMobileDescTitle className="title">
        {name}
      </SlideMobileDescTitle>
      <SlideMobileDescText className="text">
        {desc}
      </SlideMobileDescText>
    </SlideMobileDesc>
  </Slide>
);

const Workshops = ({ data }) => {
  useEffect(() => {
    speakersSlider = new Glide('.glide__workshops', {
      type: 'slider',
      perView: 2,
      focusAt: 0,
      peek: 200,
      startAt: 0,
      slideWidth: 540,
    });

    speakersSlider2 = new Glide('.glide__workshops2', {
      type: 'slider',
      perView: 2,
      focusAt: 0,
      peek: 200,
      startAt: 0,
      slideWidth: 540,
    });

    speakersSlider.mount({ Swipe, Controls, Breakpoints });

    document.querySelector('.slide-speakers-prev').addEventListener('click', () => speakersSlider.go('<'));
    document.querySelector('.slide-speakers-next').addEventListener('click', () => speakersSlider.go('>'));

    speakersSlider2.mount({ Swipe, Controls, Breakpoints });

    document.querySelector('.slide2-speakers-prev').addEventListener('click', () => speakersSlider2.go('<'));
    document.querySelector('.slide2-speakers-next').addEventListener('click', () => speakersSlider2.go('>'));
  }, []);

  return (
    <Page id="warsztaty" className="page page--dark">
      <PageBackground />
      <PageTitle>
        Warsztaty
      </PageTitle>
      {/* <SpeakersList> */}
      {/*  LEO BIGGER | RENE WAGNER<br/> */}
      {/*  WIKTORIA MACIEJEWICZ | KAROL MICHALAK<br/> */}
      {/*  ARKADIUSZ KUCZYŃSKI | MICHAŁ WŁODARCZYK */}
      {/* </SpeakersList> */}
      <GlideSlider className="glide__workshops">
        <div data-glide-el="track" className="glide__track">
          <GlideUl className="glide__slides">
            <Workshop
              author="Leo Bigger"
              name="Powołanie i wizja"
              desc="Jak nie stracić z oczu Bożej perspektywy?"
              img={data.leobigger2.childImageSharp.fluid}
            />
            <Workshop
              author="René Wagner"
              name="Jezu, tak się nie robi!"
              desc="Jak się modlić gdy cierpię?"
              img={data.rene2.childImageSharp.fluid}
            />
            <Workshop
              author="Daniel Różański"
              name="Zbuduję ci dom"
              desc="Jak tworzyć współczesny kościół?"
              img={data.rozanski.childImageSharp.fluid}
            />
            <Workshop
              author="Michał Włodarczyk"
              name="Jak brzmi Bóg?"
              desc="Co zrobić żeby Go usłyszeć?"
              img={data.wlodarczyk2.childImageSharp.fluid}
            />
            <Workshop
              author="Marek Sobotka"
              name="Sex, kobiety i tatuaże"
              desc="Czyli kontrowersyjne tematy w kościele - Q&A"
              img={data.sobotka.childImageSharp.fluid}
            />
            <Workshop
              author="Arek Krzywodajć"
              name="Gorszy czas"
              desc="Jak do tego doszło?"
              img={data.krzywodajc.childImageSharp.fluid}
            />
            <Workshop
              author="Karol i Agnieszka Michalak"
              name="Jestem, więc myślę"
              desc="Jak rozwijać kreatywność?"
              img={data.michalak2.childImageSharp.fluid}
            />
            <Workshop
              author="Leo Bigger"
              name="Zmień swój mental"
              desc="Jakie cechy powinien mieć zdrowy lider?"
              img={data.leobigger2.childImageSharp.fluid}
            />
            <Workshop
              author="Michał Włodarczyk"
              name="Biblia mówi"
              desc="Jak odkrywać ją wciąż na nowo?"
              img={data.wlodarczyk2.childImageSharp.fluid}
            />
            <Workshop
              author="Daniel Różański"
              name="Ja i mój “świat”"
              desc="Zdrowy balans w relacjach"
              img={data.rozanski.childImageSharp.fluid}
            />
          </GlideUl>
        </div>
        <SliderNav>
          <SlideArrowLeft className="slide-speakers-prev">
            <Img fluid={data.slide_arrow.childImageSharp.fluid} />
          </SlideArrowLeft>
          <GlideBulletsContainer className="glide__bullets" data-glide-el="controls[nav]">
            <GlideBullet className="glide__bullet" data-glide-dir="=0" />
            <GlideBullet className="glide__bullet" data-glide-dir="=1" />
            <GlideBullet className="glide__bullet" data-glide-dir="=2" />
            <GlideBullet className="glide__bullet" data-glide-dir="=3" />
            <GlideBullet className="glide__bullet" data-glide-dir="=4" />
            <GlideBullet className="glide__bullet" data-glide-dir="=5" />
            <GlideBullet className="glide__bullet" data-glide-dir="=6" />
            <GlideBullet className="glide__bullet" data-glide-dir="=7" />
          </GlideBulletsContainer>
          <SlideArrowRight className="slide-speakers-next">
            <Img fluid={data.slide_arrow.childImageSharp.fluid} />
          </SlideArrowRight>
        </SliderNav>
      </GlideSlider>

      <MobileSpeakers>
        <WorkshopMobile
          author="Leo Bigger"
          name="Powołanie i wizja"
          desc="Jak nie stracić z oczu Bożej perspektywy?"
          img={data.leobigger2.childImageSharp.fluid}
        />
        <WorkshopMobile
          author="René Wagner"
          name="Jezu, tak się nie robi!"
          desc="Jak się modlić gdy cierpię?"
          img={data.rene2.childImageSharp.fluid}
        />
        <WorkshopMobile
          author="Daniel Różański"
          name="Zbuduję ci dom"
          desc="Jak tworzyć współczesny kościół?"
          img={data.rozanski.childImageSharp.fluid}
        />
        <WorkshopMobile
          author="Michał Włodarczyk"
          name="Jak brzmi Bóg?"
          desc="Co zrobić żeby Go usłyszeć?"
          img={data.wlodarczyk2.childImageSharp.fluid}
        />
        <WorkshopMobile
          author="Daniel Różański"
          name="Ja i mój “świat”"
          desc="Zdrowy balans w relacjach"
          img={data.rozanski.childImageSharp.fluid}
        />
        <WorkshopMobile
          author="Arek Krzywodajć"
          name="Gorszy czas"
          desc="Jak do tego doszło?"
          img={data.krzywodajc.childImageSharp.fluid}
        />
        <WorkshopMobile
          author="Karol i Agnieszka Michalak"
          name="Jestem, więc myślę"
          desc="Jak rozwijać kreatywność?"
          img={data.michalak2.childImageSharp.fluid}
        />
        <WorkshopMobile
          author="Leo Bigger"
          name="Zmień swój mental"
          desc="Jakie cechy powinien mieć zdrowy lider?"
          img={data.leobigger2.childImageSharp.fluid}
        />
        <WorkshopMobile
          author="Michał Włodarczyk"
          name="Biblia mówi"
          desc="Jak odkrywać ją wciąż na nowo?"
          img={data.wlodarczyk2.childImageSharp.fluid}
        />
      </MobileSpeakers>

      {/**/}

      <GlideSlider className="glide__workshops2">
        <div data-glide-el="track" className="glide__track">
          <GlideUl className="glide__slides">
            <Workshop
              author="Jakub Skafiriak"
              name="Branding"
              desc="Praktyczne budowanie brandu w kościele"
              img={data.branding.childImageSharp.fluid}
            />
            <Workshop
              author="Aneta Wigłasz"
              name="Foto"
              desc="Fotografia w teorii i praktyce"
              img={data.foto.childImageSharp.fluid}
            />
            <Workshop
              author="Daniel Godson"
              name="Skateboarding"
              desc="Weź deskę i pokaż na co się stać"
              img={data.skateboarding.childImageSharp.fluid}
            />
            <Workshop
              author="Samuel Nguyen Van"
              name="ZOSTAŃ BARISTĄ"
              desc="jak szybko i łatwo zaparzyć najlepszą kawę w mieście"
              img={data.barista.childImageSharp.fluid}
            />
            <Workshop
              author="Mohammad Akbari"
              name="FREESTYLE FOOTBALL"
              desc="piłkarskie triki z piłką"
              img={data.freestyle.childImageSharp.fluid}
            />
            <Workshop
              author="Justyna Lewandowska"
              name="COFFEE PAINTING"
              desc="Odkryj technikę malowania kawą"
              img={data.coffee.childImageSharp.fluid}
            />
            <Workshop
              author="Sylwia Basiak"
              name="BĄDŹ FIT"
              desc="fitness przy dźwiękach energetyzującej muzyki"
              img={data.fit.childImageSharp.fluid}
            />
            <Workshop
              author="Ewa Kopyto"
              name="DENS MA SENS"
              desc="daj się porwać muzyce i uwolnij się w tańcu"
              img={data.dens.childImageSharp.fluid}
            />
          </GlideUl>
        </div>
        <SliderNav>
          <SlideArrowLeft className="slide2-speakers-prev">
            <Img fluid={data.slide_arrow.childImageSharp.fluid} />
          </SlideArrowLeft>
          <GlideBulletsContainer className="glide__bullets2" data-glide-el="controls[nav]">
            <GlideBullet className="glide__bullet" data-glide-dir="=0" />
            <GlideBullet className="glide__bullet" data-glide-dir="=1" />
            <GlideBullet className="glide__bullet" data-glide-dir="=2" />
            <GlideBullet className="glide__bullet" data-glide-dir="=3" />
            <GlideBullet className="glide__bullet" data-glide-dir="=4" />
            <GlideBullet className="glide__bullet" data-glide-dir="=5" />
            <GlideBullet className="glide__bullet" data-glide-dir="=6" />
            <GlideBullet className="glide__bullet" data-glide-dir="=7" />
          </GlideBulletsContainer>
          <SlideArrowRight className="slide2-speakers-next">
            <Img fluid={data.slide_arrow.childImageSharp.fluid} />
          </SlideArrowRight>
        </SliderNav>
      </GlideSlider>
      <MobileSpeakers>
        <WorkshopMobile
          author="Jakub Skafiriak"
          name="Branding"
          desc="Praktyczne budowanie brandu w kościele"
          img={data.branding.childImageSharp.fluid}
        />
        <WorkshopMobile
          author="Aneta Wigłasz"
          name="Foto"
          desc="Fotografia w teorii i praktyce"
          img={data.foto.childImageSharp.fluid}
        />
        <WorkshopMobile
          author="Daniel Godson"
          name="Skateboarding"
          desc="Weź deskę i pokaż na co się stać"
          img={data.skateboarding.childImageSharp.fluid}
        />
        <WorkshopMobile
          author="Samuel Nguyen Van"
          name="ZOSTAŃ BARISTĄ"
          desc="jak szybko i łatwo zaparzyć najlepszą kawę w mieście"
          img={data.barista.childImageSharp.fluid}
        />
        <WorkshopMobile
          author="Mohammad Akbari"
          name="FREESTYLE FOOTBALL"
          desc="piłkarskie triki z piłką"
          img={data.freestyle.childImageSharp.fluid}
        />
        <WorkshopMobile
          author="Justyna Lewandowska"
          name="COFFEE PAINTING"
          desc="Odkryj technikę malowania kawą"
          img={data.coffee.childImageSharp.fluid}
        />
        <WorkshopMobile
          author="Sylwia Basiak"
          name="BĄDŹ FIT"
          desc="fitness przy dźwiękach energetyzującej muzyki"
          img={data.fit.childImageSharp.fluid}
        />
        <WorkshopMobile
          author="Ewa Kopyto"
          name="DENS MA SENS"
          desc="daj się porwać muzyce i uwolnij się w tańcu"
          img={data.dens.childImageSharp.fluid}
        />
      </MobileSpeakers>
    </Page>
  );
};

export default Workshops;
